/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TrashIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import Header from '../../../../components/layout/PageHeader';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import JSONConfig from '../Json';
import DeleteConfirm from '../../../../components/DeleteConfirm';
// import YamlCard from '../YamlCard';
import RefreshConfirm from '../RefreshConfirm';
import History from '../History';
import Tabs from './Tabs';
import InfoCard from './InfoCard';
import { formatDateTime } from '../../../../helpers/DateHelpers';
import WebhookTopicCard from '../Webhook/TopicCard';
import WebhookEndpointCard from '../Webhook/EndpointCard';
import WebhookSecurity from '../Webhook/Security';
import ReadMeCard from '../ReadMe';
import ServerInfoCard from './ServerInfoCard';
import ActionRequired from './ActionRequiredCard';
import useMe from '../../../../context/CurrentUserContext';
import WebhookHistoryCard from '../Webhook/HistoryCard';
import WorkflowCards from '../Workflows';
import ApiCards from '../API';

export default function View({
  connection, connectionTemplate = null, onRetry, onDestroy,
}) {
  const [modal, setModal] = useState(null);
  const [currentTab, setCurrentTab] = useState('General Info');
  const { adminView } = useMe();

  const buttons = adminView ? [
    { label: 'Delete', icon: TrashIcon, onClick: () => setModal('delete') },
    // { label: 'Pause', icon: PlayPauseIcon, onClick: () => setShowPauseConfirm(true) },
    // { label: 'Edit', icon: PencilIcon, onClick: () => navigate('edit') },
    { label: 'Refresh', icon: ArrowPathIcon, onClick: () => setModal('refresh') },
  ] : [];

  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl">
      <DeleteConfirm
        title="Delete Connection"
        description="Are you sure you want to permanently delete this connection?"
        open={modal === 'delete'}
        onClose={() => setModal(null)}
        onConfirm={onDestroy}
      />
      <RefreshConfirm
        title="Refresh Credentials"
        description="Are you sure you want to retry this connection's credentials?"
        open={modal === 'refresh'}
        onClose={() => setModal(null)}
        onConfirm={onRetry}
      />
      <Header
        description={`Added on ${formatDateTime(connection.inserted_at)}.`}
        name={connection.name}
        buttons={buttons}
        avatar={connectionAvatar(connection, { size: 'lg' })}
      />
      <div className="mt-8 max-w-3xl lg:max-w-7xl">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 ">
          <Tabs
            connection={connection}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
            isTemplate={connection.template}
          />
          {
            currentTab === 'General Info'
              ? (
                <>
                  <ActionRequired connection={connection} />
                  <InfoCard connection={connection} connectionTemplate={connectionTemplate} />
                  <History connection={connection} />
                </>
              )
              : null
          }
          {
            currentTab === 'Server Details'
              ? <ServerInfoCard connection={connection} />
              : null
          }
          { currentTab === 'API Details' ? <ApiCards connection={connection} /> : null }
          {
            currentTab === 'Webhook Details'
              ? (
                <>
                  <WebhookEndpointCard connection={connection} />
                  <WebhookTopicCard connection={connection} />
                  <WebhookSecurity connection={connection} />
                  <WebhookHistoryCard connection={connection} />
                </>
              )
              : null
          }
          { currentTab === 'Workflows' ? <WorkflowCards connection={connection} connectionTemplate={connectionTemplate} /> : null }
          {
            currentTab === 'Configuration'
              ? (
                <>
                  <JSONConfig connection={connection} />
                  {/* <YamlCard connection={connection} /> */}
                </>
              )
              : null
          }
          {
            currentTab === 'Read Me'
              ? <ReadMeCard connection={connection} />
              : null
          }
          {/*
            <WebhookConfiguration connection={connection} />
            <ConnectionsCard connection={connection} />
            */}
        </div>
      </div>
    </div>

  );
}

View.propTypes = {
  connection: ConnectionShape.isRequired,
  connectionTemplate: ConnectionShape,
  onDestroy: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};
