/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import useMe from '../../../../context/CurrentUserContext';

export default function Tabs({ currentTab, setCurrentTab, isTemplate = null, connection }) {
  const { adminView } = useMe();

  const apiTabs = ['General Info', 'API Details', 'Webhook Details', 'Workflows'];
  if (adminView) apiTabs.push('Configuration');
  const dbTabs = ['General Info', 'Server Details'];
  if (adminView) dbTabs.push('Configuration');
  // const readmeTab = ['Read Me'];

  const tabs = connection.api ? apiTabs : dbTabs;
  // const withReadMe = isTemplate ? [...tabs, ...readmeTab] : tabs;
  return (
    <div className="mt-6 sm:mt-2 2xl:mt-5">
      <div className="border-b border-gray-200">
        <nav aria-label="Tabs" className="-mb-px flex space-x-8">
          {tabs.map((tab) => (
            <button
              key={tab}
              type="button"
              onClick={() => setCurrentTab(tab)}
              className={classNames(
                currentTab === tab
                  ? 'border-indigo-500 text-gray-900'
                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium',
              )}
            >
              {tab}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

Tabs.propTypes = {
  currentTab: PropTypes.string.isRequired,
  setCurrentTab: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool,
  connection: ConnectionShape.isRequired,
};
