/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
import EventShape from '../../../shapes/EventShape';
import ConnectionShape from '../../../shapes/ConnectionShape';
import ShowPageSectionCard from '../../../components/layout/ShowPage/SectionCard';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import LinkButton from '../../../components/buttons/LinkButton';

const statusColor = {
  resolved: 'text-teal-500 bg-teal-500/10 ring-teal-500',
  pending: 'text-gray-400 bg-gray-400/10 ring-gray-400',
  unresolved: 'text-indigo-400 bg-indigo-400/10 ring-indigo-400/30',
};

export default function InfoCard({ event, connection = null }) {
  return (
    <ShowPageSectionCard
      title="Event Information"
      subTitle="General details about this event"
    >
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Source</dt>
          <dd className="mt-1 text-sm text-gray-900 flex">
            <div className="mr-1 mt-0.5">
              {connectionAvatar(connection, { size: 'xxs' })}
            </div>
            {connection?.name || ''}
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Event Topic</dt>
          <dd className="mt-1 text-sm text-gray-900">{event.topic}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Timestamp</dt>
          <dd className="mt-1 text-sm text-gray-900">{event.inserted_at}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Previous Event</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <LinkButton
              buttonText={event.previous_event_id}
              path={`/events/${event.previous_event_id}`}
            />
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Status</dt>
          <dd className="mt-1 text-sm text-gray-900 flex">
            <div className={classNames(
              statusColor[event.status],
              'rounded flex-none py-1 px-2 text-xs ring-1 ring-inset flex',
            )}
            >
              {event.status}
              {event.status === 'resolved' ? <CheckCircleIcon className="w-3 h-3 m-0.5" /> : null }
              {event.status === 'pending' ? '...' : null }
            </div>
          </dd>
        </div>
      </dl>
    </ShowPageSectionCard>
  );
}

InfoCard.propTypes = {
  event: EventShape.isRequired,
  connection: ConnectionShape,
};
