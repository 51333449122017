/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { DateTime } from 'luxon';
import Autocomplete from '../../../components/forms/Autocomplete';
import ComboBox from '../../../components/forms/ComboBox';
import InputBase from '../../../components/forms/InputBase';
import useMe from '../../../context/CurrentUserContext';
import IndexClientShape from '../../../shapes/IndexClientShape';

export default function FilterForm({ indexClient }) {
  const { connections, eventTopics } = useMe();
  const [timeFrame, setTimeFrame] = useState(null);
  const onCloseModal = () => indexClient.setFilterOpen(false);

  return (
    <form onSubmit={indexClient.onSubmit}>
      <div>
        <ComboBox
          form={indexClient.filterForm}
          label="Source Connection"
          name="connection_id"
          options={connections}
          labelKey="name"
          valueKey="id"
          imageKey="icon_url"
        />
      </div>
      <div>
        <ComboBox
          form={indexClient.filterForm}
          label="Status"
          name="status"
          options={[
            { label: 'Manually Resolved', value: 'manually_resolved' },
            { label: 'Pending', value: 'pending' },
            { label: 'Resolved', value: 'resolved' },
            { label: 'Unresolved', value: 'unresolved' },
          ]}
          labelKey="label"
          valueKey="value"
        />
      </div>
      <div>
        <InputBase
          form={indexClient.filterForm}
          label="Thread ID"
          placeholder="Thread ID..."
          name="thread"
        />
      </div>
      <div>
        <ComboBox
          form={indexClient.filterForm}
          label="Timeframe"
          name="connection_id"
          preventSort
          options={[
            { value: 'minutes', label: 'Last Minute' },
            { value: 'hours', label: 'Last Hour' },
            { value: 'days', label: 'Last Day' },
            { value: 'months', label: 'Last Month' },
          ]}
          onChangeOverride={(v) => {
            setTimeFrame(v.value);
            indexClient.filterForm.setValue('start_date', DateTime.now().minus({ [v.value]: 1 }).toISO());
            // indexClient.filterForm.setValue('end_date', DateTime.now());
          }}
          valueOverride={timeFrame}
        />
      </div>
      <Autocomplete
        form={indexClient.filterForm}
        label="Topic"
        name="topic"
        placeholder="Topic..."
        values={eventTopics}
      />
      <div className="mt-6 flex justify-space-between">
        <button
          onClick={onCloseModal}
          type="button"
          className="mr-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          // disabled={isSubmitting}
          className="ml-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Filter
        </button>
      </div>
    </form>
  );
}

FilterForm.propTypes = {
  // isSubmitting: PropTypes.bool.isRequired,
  indexClient: IndexClientShape.isRequired,
};
