import React from 'react';
import PropTypes from 'prop-types';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/PageHeader';
import FormShape from '../../../shapes/FormShape';
import SubForm from './SubForm';
import Footer from '../../../components/forms/Footer';
import FieldsSubForm from './FieldsSubForm';

export default function Form({
  title,
  description,
  form,
  isSubmitting,
  onSubmit,
}) {
  const navigate = useNavigate();
  return (
    <form onSubmit={onSubmit}>
      <Header
        name={title}
        description={description}
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <SubForm form={form} />
      <FieldsSubForm form={form} />
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
        <Footer
          form={form}
          backButtonText="Back"
          onBackButtonClick={() => navigate(-1)}
          submitButtonText="Submit"
          submitButtonDisabled={isSubmitting}
          submitButtonType="submit"
        />
      </div>
    </form>
  );
}

Form.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  form: FormShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};
