/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import ConnectionShape from '../../../shapes/ConnectionShape';
import useClient from '../../../hooks/useClient';
import Modal from '../../../components/Modal';
import LinkButton from '../../../components/buttons/LinkButton';
import { formatDateTime } from '../../../helpers/DateHelpers';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Loading from '../../../components/Loading';
import LogDiffModal from '../../../components/LogDiffModal';

export default function Logs({ connection }) {
  const [currentLog, setCurrentLog] = useState(null);
  const [page, setPage] = useState(1);
  const [xLogs, setLogs] = useState([]);
  const [isLoading, setIsLoadingLogs] = useState(true);

  const { data, meta } = useClient('logs').list({
    records: { connection_id: connection.id }, page_size: 100, page,
  });

  useEffect(() => {
    setLogs([...logs, ...(data || [])]);
    if (meta?.page && meta.page < meta.total_pages) {
      setPage(page + 1);
    } else {
      setIsLoadingLogs(false);
    }
  }, [meta?.page]);

  const logs = xLogs?.filter((l) => l.topic === 'connection.created' || l.topic === 'connection.updated');
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Connection History
            </h2>
          </div>
          {
            currentLog
              ? (
                <Modal maxWidth=":w-full" open onClose={() => setCurrentLog(null)} fullScreen>
                  <LogDiffModal
                    log={{ ...currentLog, updateLogs: logs }}
                    onClose={() => setCurrentLog(null)}
                  />
                </Modal>
              )
              : null
          }
          {
          isLoading
            ? (<Loading />)
            : (
              <div className="inline-block min-w-full border-b border-gray-200 align-middle p-4">
                <table className="min-w-full  border border-gray-200">
                  <thead>
                    <tr>
                      <th scope="col" className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        Topic
                      </th>
                      <th scope="col" className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900">
                        Timestamp
                      </th>
                      <th scope="col" className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900">
                        <span className="sr-only">Details</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-300 bg-white">
                    {logs.map((log) => (
                      <tr key={log.id}>
                        <td className="text-gray-600 whitespace-nowrap px-3 py-4 text-sm font-medium rounded-sm">
                          {log.topic}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{formatDateTime(log.inserted_at)}</td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <LinkButton buttonText="Details" onClick={() => setCurrentLog(log)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
      </ErrorBoundary>
    </section>
  );
}

Logs.propTypes = {
  connection: ConnectionShape.isRequired,
};
