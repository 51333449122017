/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import FormShape from '../../shapes/FormShape';
import Input from '../forms/InputBase';
import { readableArray } from '../../helpers/ArrayHelpers';
import StatusTabs from '../IndexPage/StatusTabs';
import DateFilter from '../IndexPage/DateFilter';
import IndexClientShape from '../../shapes/IndexClientShape';

function FilterChip({
  filterForm, urlParamsObj, onSubmit, statusTabConfig = null, dateFilterConfig = null,
}) {
  const filterKeys = Object.keys(urlParamsObj);
  return (
    <>
      {
        filterKeys.map((filterKey) => (
          (
            filterKey === 'page'
            || (statusTabConfig?.filterKey && statusTabConfig?.filterKey === filterKey)
            || (dateFilterConfig && ['start_date', 'end_date'].includes(filterKey)))
            ? null
            : (
              <span key={filterKey} className="my-4 mr-2 inline-flex items-center rounded bg-indigo-100 border border-indigo-400 py-1 pl-2.5 pr-1 text-sm font-medium text-indigo-700">
                <b>{filterKey}</b>
                :
                {' '}
                {
              Array.isArray(urlParamsObj[filterKey])
                ? readableArray(urlParamsObj[filterKey])
                : urlParamsObj[filterKey]
            }
                <button
                  type="button"
                  onClick={() => {
                    filterForm.setValue(filterKey, null);
                    onSubmit();
                  }}
                  className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-indigo-400 hover:bg-indigo-200 hover:text-indigo-500 focus:bg-indigo-500 focus:text-white focus:outline-none"
                >
                  <span className="sr-only">Remove Filter</span>
                  <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                    <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                  </svg>
                </button>
              </span>
            )
        ))
      }
    </>
  );
}

FilterChip.propTypes = {
  filterForm: FormShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  urlParamsObj: PropTypes.shape({}).isRequired,
  statusTabConfig: PropTypes.shape(),
  dateFilterConfig: PropTypes.shape(),
};

export default function SearchBar({
  label, name, indexClient, placeholder, statusTabConfig = null, dateFilterConfig = null,
}) {
  const { filterForm, onSubmit, urlParamsObj } = indexClient;
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl">
      <div className="flex flex-wrap md:flex-no-wrap items-center justify-between w-full mt-10">
        <div className="flex flex-col md:flex-row w-full items-start md:items-center">
          <div className="w-full md:w-3/4 lg:w-3/5 xl:w-1/2 h-16">
            <div className="inline-block align-baseline w-full">
              <Input
                inputClass="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded w-full"
                form={filterForm}
                label={label}
                name={name}
                placeholder={placeholder}
              >
                <button
                  type="button"
                  onClick={onSubmit}
                  className="ml-2 inline-flex items-center rounded border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Search
                  <MagnifyingGlassCircleIcon className="ml-2 -mr-0.5 h-5 w-5" aria-hidden="true" />
                </button>
              </Input>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full items-start md:items-center mb-4">
          <div className="w-full">
            <FilterChip
              filterForm={filterForm}
              urlParamsObj={urlParamsObj}
              onSubmit={onSubmit}
              statusTabConfig={statusTabConfig}
              dateFilterConfig={dateFilterConfig}
            />
          </div>
        </div>
        <div className="border border-gray-300 rounded mt-2 w-full bg-gray-100">
          {
            statusTabConfig
              ? (
                <StatusTabs
                  indexClient={indexClient}
                  config={statusTabConfig}
                  displayBottomBorder={!!dateFilterConfig}
                />
              )
              : null
          }
          {
            dateFilterConfig
              ? <DateFilter indexClient={indexClient} />
              : null
          }
        </div>
      </div>
    </div>
  );
}

SearchBar.propTypes = {
  indexClient: IndexClientShape.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  statusTabConfig: PropTypes.shape(),
  dateFilterConfig: PropTypes.shape(),
};
