/* eslint-disable react/require-default-props */
import React from 'react';
import { Link } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import ConnectionShape from '../../../../shapes/ConnectionShape';

export default function ActionRequired({ connection }) {
  // Catch OAUTH 2 connections that are not installed.
  // Ensure that this does not trigger for templates
  if (!connection.template && connection.api?.authentication?.record_type === 'oauth2' && !connection.api?.authentication?.access_token) {
    return (
      <section aria-labelledby="action-information-title">
        <ErrorBoundary>
          <div className="sm:col-span-2">
            <dd className="mt-1 text-sm text-gray-900">
              <div className="rounded-md bg-yellow-50 border border-yellow-400 p-4">
                <div className="flex space-x-2">
                  <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                  <h3 className="text-sm font-medium text-yellow-800">Pending Install</h3>
                </div>
                <div className="flex text-sm text-yellow-700 space-x-1">
                  <p>This app is pending installation.</p>
                  <p>Click</p>
                  <Link
                    className="text-indigo-700"
                    to={connection.api.authentication?.destination_redirect_url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </Link>
                  <p>to open a new tab and install.</p>
                </div>
              </div>
            </dd>
          </div>
        </ErrorBoundary>
      </section>
    );
  }
  return null;
}

ActionRequired.propTypes = {
  connection: ConnectionShape.isRequired,
};
