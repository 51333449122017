/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { fromSearchParams } from '../../helpers/ObjectHelpers';
import IndexClientShape from '../../shapes/IndexClientShape';

export default function StatusTabs({ indexClient, config, displayBottomBorder }) {
  const [searchParams] = useSearchParams();
  const searchParamsObj = fromSearchParams(searchParams);
  const value = searchParamsObj ? searchParamsObj[config.filterKey] : null;

  return (
    <div className={classNames('w-full p-3', displayBottomBorder ? 'border-b border-gray-300' : null)}>
      <div className="sm:hidden">
        <label htmlFor="Status Filter" className="w-36 block text-sm font-medium text-gray-700">
          {config.label}
        </label>
        <select
          id="current-tab"
          name="current-tab"
          onChange={(e) => {
            indexClient.filterForm.setValue(config.filterKey, e.target.value);
            indexClient.onSubmit();
          }}
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
          defaultValue={value}
        >
          {config.tabs.map((tab) => (
            <option key={tab.value} value={tab.value}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:flex sm:space-x-8">
        <label htmlFor="Status Filter" className="w-36 block text-sm font-medium text-gray-700">
          {config.label}
          :
        </label>
        <nav className="flex space-x-4">
          {config.tabs.map((tab) => (
            <button
              type="button"
              key={tab.name}
              onClick={() => {
                indexClient.filterForm.setValue(config.filterKey, tab.value);
                indexClient.onSubmit();
              }}
              className={classNames(
                'rounded-sm text-xs font-medium px-2 border w-32 h-6 border-gray-300',
                tab.value == value
                  ? 'bg-indigo-50 text-indigo-700 border-indigo-700'
                  : 'text-gray-500 hover:text-gray-700',
              )}
              aria-current={tab.value == value ? 'page' : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

StatusTabs.propTypes = {
  indexClient: IndexClientShape.isRequired,
  config: PropTypes.shape().isRequired,
  displayBottomBorder: PropTypes.bool.isRequired,
};
