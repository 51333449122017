/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseBadge from './Badge';

export default function EventStatusBadge({ status = null }) {
  if (!status) return null;
  let color = 'gray';
  if (status === 'resolved') color = 'teal';
  if (status === 'pending') color = 'yellow';

  return <BaseBadge color={color} message={status} />;
}

EventStatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};
