/* eslint-disable no-nested-ternary */
import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';

const steps = [
  'Connection Template',
  'Connection Details',
  'Server Setup',
];

export default function ProgressBar({ currentStepIndex }) {
  return (
    <nav aria-label="Progress" className="mx-auto max-w-3xl lg:max-w-7xl mt-10 bg-white">
      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0 mt-10">
        {steps.map((step, stepIdx) => (
          <li key={step} className="relative md:flex md:flex-1">
            {stepIdx < currentStepIndex ? (
              <span className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex size-10 shrink-0 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                    <CheckIcon aria-hidden="true" className="size-6 text-white" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step}</span>
                </span>
              </span>
            ) : currentStepIndex === stepIdx ? (
              <span className="flex items-center px-6 py-4 text-sm font-medium">
                <span className="flex size-10 shrink-0 items-center justify-center rounded-full border-2 border-indigo-600">
                  <span className="text-indigo-600">{stepIdx + 1}</span>
                </span>
                <span className="ml-4 text-sm font-medium text-indigo-600">{step}</span>
              </span>
            ) : (
              <span className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex size-10 shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
                    <span className="text-gray-500">{stepIdx + 1}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-500">{step}</span>
                </span>
              </span>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div aria-hidden="true" className="absolute right-0 top-0 hidden h-full w-5 md:block">
                  <svg fill="none" viewBox="0 0 22 80" preserveAspectRatio="none" className="size-full text-gray-300">
                    <path
                      d="M0 -2L20 40L0 82"
                      stroke="currentcolor"
                      vectorEffect="non-scaling-stroke"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}

ProgressBar.propTypes = {
  currentStepIndex: PropTypes.number.isRequired,
}