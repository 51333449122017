/* eslint-disable react/require-default-props */
import React from 'react';

import { DateTime } from 'luxon';
import EventShape from '../../../shapes/EventShape';
import ConnectionShape from '../../../shapes/ConnectionShape';
import ShowPageSectionCard from '../../../components/layout/ShowPage/SectionCard';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import EventStatusBadge from '../../../components/Badges/EventStatusBadge';
import { formatDate, formatDateTimeWithSeconds } from '../../../helpers/DateHelpers';
import LinkButton from '../../../components/buttons/LinkButton';

export default function InfoCard({ event, connection = null }) {
  return (
    <ShowPageSectionCard
      title="Event Information"
      // subTitle="General details about this event"
    >
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Source</dt>
          <dd className="mt-1 text-sm text-gray-900 flex">
            <div className="mr-1 mt-0.5">
              {connectionAvatar(connection, { size: 'xxs' })}
            </div>
            <LinkButton buttonText={connection?.name} path={`/connections/${connection.id}`} />
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Event Topic</dt>
          <dd className="mt-1 text-sm text-gray-900">{event.topic}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Status</dt>
          <dd className="mt-1 text-sm text-gray-900 flex -ml-2">
            <EventStatusBadge status={event.status} />
          </dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Timestamp</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <span className="mr-2">
              {formatDate(event.inserted_at, DateTime.DATE_MED)}
            </span>
            <span>
              {formatDateTimeWithSeconds(event.inserted_at)}
            </span>
          </dd>
        </div>
      </dl>
    </ShowPageSectionCard>
  );
}

InfoCard.propTypes = {
  event: EventShape.isRequired,
  connection: ConnectionShape,
};
