/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';

export default function WorkflowTableHeader() {
  return (
    <thead>
      <tr className="border-t border-gray-200">
        <th
          className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
          scope="col"
        >
          Workflow
        </th>
        <th
          className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 lg:table-cell"
          scope="col"
        >
          Actions
        </th>
        <th
          className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900"
          scope="col"
        />
      </tr>
    </thead>
  );
}
