/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Input from '../../../../components/forms/InputBase';
import FormShape from '../../../../shapes/FormShape';
import MetadataSubForm from './MetadataSubForm';
import JSONInput from '../../../../components/forms/JSONInput';

export default function SubForm({ form }) {
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
      <div className="w-full bg-white px-10 border border-gray-300 rounded">
        <div className="pt-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Details</h3>
          <p className="mt-1 text-sm text-gray-500">
            Let's start by giving this resource a name, icon, and path.
          </p>
        </div>
        <div className="xl:flex xl:space-x-4 mt-2">
          <div className="pt-2 xl:w-1/2">
            <Input
              className="xl:w-1/2"
              form={form}
              label="Name"
              name="name"
              placeholder="Name..."
              required
            />
          </div>
          <div className="pt-2 xl:w-1/2">
            <Input
              className="xl:w-1/2"
              form={form}
              label="Icon URL"
              name="icon_url"
              placeholder="Icon URL..."
              required
            />
          </div>
        </div>
        <div className="xl:flex xl:space-x-4 mt-2">
          <div className="pt-2 xl:w-1/2">
            <Input
              className="xl:w-1/2"
              form={form}
              label="Path"
              name="path"
              placeholder="Path..."
              required
            />
          </div>
        </div>
        <div className="w-full mt-2">
          <JSONInput form={form} label="Filters" name="filters" />
        </div>
        <div className="py-2">
          <MetadataSubForm form={form} />
        </div>
      </div>
    </div>
  );
}

SubForm.propTypes = {
  form: FormShape.isRequired,
};
