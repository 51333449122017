/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import { formatHeaders } from '../../../../helpers/ConnectionHelpers';
import useClient from '../../../../hooks/useClient';
import mapErrorsToForm from '../../../../helpers/FormHelpers';
import BaseForm from '.';
import Footer from '../../../../components/forms/Footer';

export default function BaseFormEdit({ connection }) {
  const defaultValues = { name: connection.name, icon_url: connection.icon_url };
  const form = useForm({ defaultValues });
  const navigate = useNavigate();
  const client = useClient('connections');

  const { isLoading: isSubmitting, mutate } = client.update(
    connection.id,
    {
      onSuccess: ({ id }) => navigate(`/connections/${id}`),
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const onSubmit = form.handleSubmit(
    (c) => {
      const formattedData = formatHeaders(c);
      return mutate({ connection: formattedData });
    },
  );

  return (
    <BaseForm
      onSubmit={onSubmit}
      form={form}
      isSubmitting={isSubmitting}
      // onReset={onReset}
      // skipUsingTemplate={skipUsingTemplate}
      footer={(
        <Footer
          backButtonText="Back to Connection"
          onBackButtonClick={() => navigate(-1)}
          submitButtonText="Update"
          submitButtonDisabled={isSubmitting}
          submitButtonType="submit"
        />
      )}
    />
  );
}

BaseFormEdit.propTypes = {
  connection: ConnectionShape,
};
