/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircleIcon, SignalIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import TableBody from './Body';
import FilterForm from './FilterForm';
import TableHeader from './Header';
import FilterBar from '../../../components/layout/FilterBar/V2FitlerBar';
import PageHeader from '../../../components/layout/PageHeader';
import ItemList from '../../../components/ItemsList';
import useIndexFetchClient from '../../../hooks/useIndexFetchClient';
import SearchBar from '../../../components/layout/SearchBar';
import useClient from '../../../hooks/useClient';
import { extractQueryableArray } from '../../../helpers/ArrayHelpers';
import useMe from '../../../context/CurrentUserContext';

const now = DateTime.now();
const startDate = now.minus({ days: 6 }).startOf('day').toISO();
const endDate = now.plus({ days: 1 }).startOf('day').toISO();

export default function IndexPage({ title = null, staticFilters = {} }) {
  const { adminView } = useMe();

  const resourceName = title || 'Threads';
  const config = {
    clientName: 'events',
    resourceName,
    description: `A list of all ${resourceName} that are occuring in your Source Connections`,
    icon: <SignalIcon className="h-8 w-8 m-1 text-indigo-700" />,
    indexClientOptions: {
      latentSearchParams: { ...staticFilters, previous_event_id: 'none' },
      searchParams: {
        start_date: startDate,
        end_date: endDate,
      },
    },
  };

  // Fetch Index Page Base Data
  const indexClient = useIndexFetchClient(config);

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);

  const eventIds = extractQueryableArray(indexClient.data, 'id');
  const { data, meta, isInitialLoading: isLoadingLogs } = useClient('logs').list(
    { records: { event_id: eventIds }, page_size: 100, page },
    { enabled: !!eventIds.length, onError: indexClient.onError },
  );

  useEffect(() => {
    setLogs([...logs, ...(data || [])]);
    if (meta?.page && meta.page < meta.total_pages) {
      setPage(page + 1);
    }
  }, [meta?.page]);

  // Find logs to display table line data
  const findLogs = ({ id }) => logs.filter((log) => log.records.event_id === id);
  const items = indexClient.data?.map((i) => ({ ...i, logs: findLogs(i) }));

  // Buttons for Top Bar
  const navigate = useNavigate();
  const buttons = [
    { label: 'Filter', icon: AdjustmentsVerticalIcon, onClick: () => indexClient.setFilterOpen(true) },
  ];

  if (adminView) {
    buttons.push({ label: `New ${resourceName}`, icon: PlusCircleIcon, onClick: () => navigate('new') });
  }

  return (
    <div>
      <PageHeader
        description={config.description}
        name={`${config.resourceName} List`}
        buttons={buttons}
        icon={config.icon}
      />
      <FilterBar
        resource={`${config.resourceName}s`}
        indexClient={indexClient}
      >
        <FilterForm indexClient={indexClient} hideTopicInput={!!title} />
      </FilterBar>
      <SearchBar
        name="matcher"
        label={`Search ${resourceName}`}
        indexClient={indexClient}
        placeholder={`Search for ${resourceName} by ID...`}
        statusTabConfig={{
          label: 'Filter by Status',
          filterKey: 'status',
          tabs: [
            { name: 'All', value: null },
            { name: 'Resolved', value: 'resolved' },
            { name: 'Unresolved', value: 'pending' },
            { name: 'Non-Matching', value: 'noop' },
          ],
        }}
        dateFilterConfig={{
          defaultStartValue: config.indexClientOptions.searchParams.start_date,
          defaultEndValue: config.indexClientOptions.searchParams.end_date,
        }}
      />
      <ItemList
        indexClient={indexClient}
        body={(
          <TableBody
            items={items || []}
            expandedRows={indexClient.expandedRows}
            setExpandedRows={indexClient.setExpandedRows}
          />
        )}
        header={<TableHeader />}
      />
    </div>
  );
}
