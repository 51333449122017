import { PencilIcon, TrashIcon, ForwardIcon, SignalIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../components/layout/PageHeader';
import DeleteConfirm from '../../../../components/DeleteConfirm';
import ActionShape from '../../../../shapes/ActionShape';
import General from './General';
import Callback from './Callback';
import TriggerCard from './TriggerCard';
import Request from './Request';
import Logs from './Logs';
import HeaderDescription from './HeaderDescription';
import Pagination from './Pagination';
import useMe from '../../../../context/CurrentUserContext';

export default function View({ action, onDestroy }) {
  const { adminView } = useMe();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  // const location = useLocation();

  const buttons = [
    { label: 'Delete', icon: TrashIcon, onClick: () => setShowDeleteModal(true) },
    {
      label: 'Test',
      icon: SignalIcon,
      onClick: () => navigate(`/actions/${action.id}/test`),
    },
    {
      label: 'Edit',
      icon: PencilIcon,
      onClick: () => navigate(`/actions/${action.id}/edit`),
    },
  ];

  return (
    <div className="max-w-3xl lg:max-w-7xl">
      <DeleteConfirm
        title="Delete Action"
        description="Are you sure you want to permanently delete this action?"
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={onDestroy}
      />
      <div>
        <Header
          description={<HeaderDescription action={action} />}
          name="Action Detail Page"
          buttons={adminView ? buttons : []}
          icon={<ForwardIcon className="h-8 w-8 m-1 text-indigo-700" />}
        />
        <div className="mt-8 max-w-3xl lg:max-w-7xl">
          <div className="space-y-6 lg:col-span-2 lg:col-start-1 ">
            <General action={action} />
            <TriggerCard action={action} />
            <Request action={action} />
            <Pagination action={action} />
            <Callback action={action} />
            <Logs action={action} />
          </div>
        </div>
      </div>
    </div>
  );
}

View.propTypes = {
  action: ActionShape.isRequired,
  onDestroy: PropTypes.func.isRequired,
};
