/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-len */
import React, { useState } from 'react';
import LinkButton from '../../../../components/buttons/LinkButton';
import Autocomplete from '../../../../components/forms/Autocomplete';
import ComboBox from '../../../../components/forms/ComboBox';
import TextArea from '../../../../components/forms/TextArea';
import FormShape from '../../../../shapes/FormShape';
import useMe from '../../../../context/CurrentUserContext';
import MidDot from '../../../../components/MidDot';

export default function MatcherSubForm({ form }) {
  const [displayMatcherInput, setDisplayMatcherInput] = useState(!!form.watch('matcher'));
  const { connections, eventTopics } = useMe();

  const toggleMatcherInput = () => {
    setDisplayMatcherInput(!displayMatcherInput);
  };

  return (
    <>
      <div className="mt-6 lg:col-span-3">
        {
          connections?.length
            ? (
              <ComboBox
                form={form}
                label="Event Source"
                name="source_connection_id"
                options={connections}
                valueKey="id"
                labelKey="name"
                imageKey="icon_url"
                required
              />
            )
            : (<p>Loading Connections...</p>)
        }
      </div>
      <div className="mt-6 lg:col-span-3">
        <Autocomplete
          form={form}
          label="Topic"
          name="topic"
          required
          placeholder="Topic..."
          values={eventTopics}
        />
      </div>
      <div className="my-6 lg:col-span-6">
        {displayMatcherInput
          ? (
            <TextArea
              form={form}
              label="Additional Filters"
              name="matcher"
              placeholder="payload.amount > 50  &&  ..."
              helperText={(
                <span className="mt-2 text-sm text-gray-500 flex justify-between">
                  Add optional filters to only act on events that meet a certain criteria.
                  <div className="flex">
                    <LinkButton
                      buttonText="Populate Example Filter"
                      onClick={() => form.setValue('matcher', 'payload.foo < payload.latest && payload.bar > 23 || payload.something == "else" && 23 <= payload.madness')}
                    />
                    <MidDot />
                    <LinkButton
                      buttonText="Cancel"
                      onClick={toggleMatcherInput}
                    />
                  </div>
                </span>
              )}
            />
          )
          : (
            <>
              <label htmlFor="matcher" className="block text-sm font-medium text-gray-700">
                Add Additional Filters?
              </label>
              <p className="mt-2 text-sm text-gray-500">
                You can add optional filters to only act on events that meet a certain criteria.
                <button type="button" className="ml-1 text-indigo-700" onClick={toggleMatcherInput}>
                  Add Additional Filters
                </button>
              </p>
            </>
          )}
      </div>

    </>
  );
}

MatcherSubForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  form: FormShape.isRequired,
};
