/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import useMe from '../../../../context/CurrentUserContext';
import { connectionAvatar } from '../../../../helpers/ConnectionHelpers';
import LinkButton from '../../../../components/buttons/LinkButton';
import ActionsList from './ActionsList';
import ActionShape from '../../../../shapes/ActionShape';
import LogShape from '../../../../shapes/LogShape';
import { formatDateTimeWithSeconds, formatDate } from '../../../../helpers/DateHelpers';

export default function EventWorkflowTrigger({ trigger, workflowActions, threadLogs }) {
  const { connections } = useMe();
  const sourceCxn = connections.find(({ id }) => id === trigger.source_connection_id);
  const callbackActions = workflowActions.filter((a) => a.topic === trigger.topic);
  const triggerLogs = threadLogs.filter((l) => l.topic === 'event.created'
    && l.body.topic === trigger.topic
    && l.body.connection_id === trigger.source_connection_id);

  return (
    <li key={`trigger-${trigger.topic}-${trigger.source_connection_id}`} className="my-5 overflow-x-scroll">
      <div className={classNames(triggerLogs.length ? 'bg-white' : 'bg-gray-100', 'border border-gray-300 rounded p-2 text-xs w-fit')}>
        {
          triggerLogs.length
            ? (
              <>
                <div className="flex border-b w-full pb-1 space-x-1">
                  {connectionAvatar(sourceCxn, { size: 'xxs' })}
                  <p className="text-gray-500 mr-1">Event Topic:</p>
                  <p>{trigger.topic}</p>
                </div>
                <div className="display:block sm:flex my-2">
                  <p className="text-gray-500 mr-1">Connection:</p>
                  <div className="text-gray-500 sm:flex space-x-1">
                    <LinkButton className="text-xs" buttonText={sourceCxn.name} path={`/connections/${sourceCxn.id}`} />
                  </div>
                </div>
                <div className="display:block sm:flex">
                  <p className="text-gray-500 mr-1">Timestamp:</p>
                  <div className="text-gray-500 sm:flex space-x-1">
                    <p>
                      {formatDate(_.join(_.map(triggerLogs, 'inserted_at'), ', '))}
                      {' '}
                      {formatDateTimeWithSeconds(_.join(_.map(triggerLogs, 'inserted_at'), ', '))}
                    </p>
                  </div>
                </div>
              </>
            )
            : (
              <span className="flex space-x-1">
                <span>
                  {connectionAvatar(sourceCxn, { size: 'xxs' })}
                </span>
                <p className="font-normal text-gray-400 mr-1">
                  Awaiting a(n)
                  {' '}
                  <b>{trigger.topic}</b>
                  {' '}
                  event from
                </p>
                <LinkButton className="text-xs text-indigo-300" buttonText={sourceCxn.name} path={`/connections/${sourceCxn.id}`} />
              </span>
            )
        }
      </div>
      {
        callbackActions.length
          ? <ActionsList callbackActions={callbackActions} workflowActions={workflowActions} threadLogs={threadLogs} />
          : null
      }
    </li>
  );
}

EventWorkflowTrigger.propTypes = {
  trigger: PropTypes.shape({
    topic: PropTypes.string.isRequired,
    source_connection_id: PropTypes.string.isRequired,
  }).isRequired,
  workflowActions: PropTypes.arrayOf(ActionShape.isRequired).isRequired,
  threadLogs: PropTypes.arrayOf(LogShape).isRequired,
};
