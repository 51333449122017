/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../components/forms/InputLockable';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import FormShape from '../../../../shapes/FormShape';

export default function BaseForm({
  onSubmit,
  connectionTemplate = null,
  skipUsingTemplate = false,
  onReset,
  form,
  footer,
}) {
  return (
    <form onSubmit={onSubmit}>
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
        <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10 pb-6">
          <div className="mt-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Connection
              {' '}
              Details
            </h3>
            {!connectionTemplate && skipUsingTemplate
              ? (
                <span className="text-sm text-gray-500">
                  Let's create a custom connection!
                  <button
                    type="button"
                    className="ml-1 text-indigo-700"
                    onClick={onReset}
                  >
                    Reset Form
                  </button>
                </span>
              ) : null}
            {connectionTemplate
              ? (
                <span className="text-sm text-gray-500">
                  Let's create a connection with the
                  {' '}
                  {connectionTemplate?.name}
                  {' '}
                  Template!
                  <button
                    type="button"
                    className="ml-1 text-indigo-700"
                    onClick={onReset}
                  >
                    Reset Form
                  </button>
                </span>
              )
              : null}
          </div>
          <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
            <div className="lg:col-span-1">
              <Input
                form={form}
                label="Name"
                name="name"
                placeholder="Name..."
              />
            </div>
            <div className="lg:col-span-1">
              <Input
                form={form}
                label="Icon URL"
                name="icon_url"
                placeholder="Icon URL..."
              />
            </div>
          </div>
        </div>
        {footer}
      </div>
    </form>
  );
}

BaseForm.propTypes = {
  connectionTemplate: ConnectionShape,
  skipUsingTemplate: PropTypes.bool,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  form: FormShape.isRequired,
  footer: PropTypes.element.isRequired,
};
