import React, { useState, useEffect } from 'react';
import Input from '../../../../../components/forms/InputBase';
import FormShape from '../../../../../shapes/FormShape';
import LinkButton from '../../../../../components/buttons/LinkButton';

export default function ApiKeySubForm({ form }) {
  const [hasBeenReset, setHasBeenReset] = useState(null);
  const [initialValue, setInitialValue] = useState(form.watch(''));

  useEffect(() => {
    setInitialValue(form.watch('api.authentication.value'));
    if (!form.watch('api.authentication.key') || !form.watch('api.authentication.value')) {
      form.setValue(
        'api.authentication',
        {
          record_type: 'api_key',
          key: '',
          value: '',
        },
      );
    }
  }, []);

  return (
    <div className="mt-2">
      <div className="mb-4">
        <Input
          form={form}
          label="Header Key"
          name="api.authentication.key"
          placeholder="Header Key..."
        />
      </div>
      <div className="mb-4">
        <Input
          type={!!initialValue && !hasBeenReset ? 'password' : 'text'}
          disabled={!!initialValue && !hasBeenReset}
          form={form}
          label="Header Value"
          name="api.authentication.value"
          placeholder="Header Value..."
          helperText={initialValue ? (
            <LinkButton
              buttonText="Reset"
              onClick={() => {
                form.setValue('api.authentication.value', '');
                setHasBeenReset(true);
              }}
            />
          ) : null}
        />
      </div>
    </div>
  );
}

ApiKeySubForm.propTypes = {
  form: FormShape.isRequired,
};
