import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import Form from '../Form';
import ResourceShape from '../../../shapes/ResourceShape';
import { useQueryClient } from '@tanstack/react-query';

export default function EditPageWrapper() {
  const { id } = useParams();
  const client = useClient('resources');
  const { data: resource, isLoading } = client.get(id);
  if (isLoading) return <Loading />;
  return (<EditPage resource={resource} />);
}

function EditPage({ resource }) {
  const navigate = useNavigate();
  const form = useForm({ defaultValues: resource });
  const queryClient = useQueryClient();
  const { isLoading: isSubmitting, mutate } = useClient('resources').update(resource.id, {
    onSuccess: () => {
      queryClient.invalidateQueries('resources')
      navigate(`/resources/${resource.id}`);
    },
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit((record) => mutate({ resource: record }));

  return (
    <Form
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      form={form}
      submitButtonText="Update Resource"
      formTitle="Update Resource Form"
      formSubTitle="Update an existing resource's name or layout."
      displayLayoutInput
    />
  );
}

EditPage.propTypes = {
  resource: ResourceShape.isRequired,
};
