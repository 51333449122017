/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import ActionShape from '../../../../shapes/ActionShape';
import ErrorBoundary from '../../../../components/ErrorBoundary';

export default function Callback({ action }) {
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Callback Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Create a response event to take action on this Action's response.</p>
          </div>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 m-4">
            {
            action.after_action
              ? (
                <>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Response Event Topic</dt>
                    <dd className="mt-1 text-sm text-gray-900">{action.after_action.topic}</dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Payload Path</dt>
                    <dd className="mt-1 text-sm text-gray-900">{action.after_action?.response_key?.join('.') || 'None'}</dd>
                  </div>

                </>
              ) : (
                <span className="col-span-2 text-sm text-gray-500 w-full">
                  You do not have a Response Event configured for this Action.
                </span>
              )
          }
          </dl>
        </div>
      </ErrorBoundary>
    </section>
  );
}

Callback.propTypes = {
  action: ActionShape.isRequired,
};
