import React from 'react';
import { useParams } from 'react-router-dom';
import useClient from '../../../hooks/useClient';
import useMe from '../../../context/CurrentUserContext';
import ConfigureAuthForm from './Form';

export default function ConfigureAuth() {
  const { id } = useParams();
  const client = useClient('connections');
  const { data: cxn, isLoading } = client.get(id);

  const { connectionTemplates: temps } = useMe();
  const connectionTemplate = cxn?.parent_id ? temps.find((t) => t.id === cxn.parent_id) : null;

  if (isLoading) return <h1>Loading...</h1>;
  if (!cxn) return <h1>Connection Not Found...</h1>;

  let defaultValues = {};
  if (connectionTemplate) {
    if (connectionTemplate.api) {
      defaultValues = { api: { ...connectionTemplate.api } };
    }
    if (connectionTemplate.database) {
      defaultValues = { database: { ...connectionTemplate.database } };
    }
  }

  return (
    <ConfigureAuthForm
      connection={cxn}
      connectionTemplate={connectionTemplate}
      defaultValues={defaultValues}
    />
  );
}
