/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TrashIcon, SignalIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { ImInsertTemplate } from 'react-icons/im';
import { useNavigate, createSearchParams } from 'react-router-dom';
import Header from '../../../components/layout/PageHeader';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import EventShape from '../../../shapes/EventShape';
import DeleteConfirm from '../../../components/DeleteConfirm';
import RetryConfirm from './RetryConfirm';
import InfoCard from './InfoCard';
import BodyWrapper from '../../../components/layout/ShowPage/BodyWrapper';
import PageWrapper from '../../../components/layout/PageWrapper';
import Payload from './Payload';
import ActionTreeCard from './ActionTreeCard';
import useMe from '../../../context/CurrentUserContext';
import { threadLabel } from '../../../helpers/EventHelpers';
import useClient from '../../../hooks/useClient';
import DescriptionTemplateModal from './DescriptionTemplateModal';

export default function View({
  event,
  onDestroy,
  onRetry,
}) {
  const [modal, setModal] = useState(null);
  const navigate = useNavigate();
  const { connections, adminView } = useMe();
  const connection = connections?.find((c) => c.id === event?.connection_id);

  const { data: events = [] } = useClient('events').list({
    thread: event.thread,
    page_size: 50,
  });

  const headerButtons = [
    { label: `${connection.metadata?.threads?.[event.topic] ? 'Edit' : 'Add'} Desc Template`, icon: ImInsertTemplate, onClick: () => setModal('desc') },
    { label: 'Retry', icon: ArrowPathIcon, onClick: () => setModal('retry') },
    {
      label: 'Create Action',
      icon: SignalIcon,
      onClick: () => {
        const search = createSearchParams({
          event_id: event.id,
          topic: event.topic,
          source_connection_id: event.connection_id,
        }).toString();
        navigate({
          pathname: '/actions/new',
          search,
        });
      },
    },
    { label: 'Delete', icon: TrashIcon, onClick: () => setModal('delete') },
  ];

  return (
    <PageWrapper>
      <DeleteConfirm
        title="Delete Thread"
        description="Are you sure you want to permanently delete this thread? All of your log records will remain, but no thread payload data will persist."
        open={modal === 'delete'}
        onClose={() => setModal(null)}
        onConfirm={onDestroy}
      />
      <RetryConfirm
        title="Retry Thread"
        description="Are you sure you want to retry this event?"
        open={modal === 'retry'}
        onClose={() => setModal(null)}
        onConfirm={onRetry}
      />
      {
        modal === 'desc' ? (
          <DescriptionTemplateModal
            onClose={() => setModal(null)}
            connection={connection}
            event={event}
            events={events}
          />
        ) : null
      }


      <Header
        description={threadLabel(event, events, connection)}
        name="Thread Detail Page"
        buttons={adminView ? headerButtons : []}
        avatar={connection ? connectionAvatar(connection, { size: 'lg' }) : null}
        icon={connection ? null : <SignalIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <BodyWrapper>
        <InfoCard event={event} connection={connection} />
        <Payload event={event} />
        {
          event.status !== 'noop'
            ? <ActionTreeCard event={event} events={events || []} />
            : null
        }
      </BodyWrapper>
    </PageWrapper>
  );
}

View.propTypes = {
  event: EventShape.isRequired,
  onDestroy: PropTypes.func.isRequired,
  onRetry: PropTypes.func.isRequired,
};
