/* eslint-disable react/require-default-props */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/24/outline';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ResourceShape from '../../../shapes/ResourceShape';
import JsonDisplay from '../../../components/JsonDisplay';

export default function InfoCard({ resource }) {
  const navigate = useNavigate();
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900 flex">
              General Information
            </h2>
            {/* <button
              key="signature"
              type="button"
              onClick={() => navigate('edit')}
              className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
            >
              <PencilIcon className="h-3 w-3 mr-2 mt-0.5 text-indigo-600" aria-hidden="true" />
              <p>Edit</p>
            </button> */}
          </div>
          <div className="mx-auto mt-6 px-4 sm:px-6 lg:px-8 mb-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{resource.name}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Path</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {resource.path}
                </dd>
              </div>
              <div className="col-span-2">
                <dt className="text-sm font-medium text-gray-500">Filters</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <JsonDisplay valueObject={resource.filters} />
                </dd>
              </div>
              <div className="col-span-2">
                <dt className="text-sm font-medium text-gray-500">Metadata</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <JsonDisplay valueObject={resource.metadata} />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
}

InfoCard.propTypes = {
  resource: ResourceShape.isRequired,
};
