/* eslint-disable camelcase */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import Footer from '../../../components/forms/Footer';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import { AxiosContext } from '../../../context/AxiosContext';
import useMe from '../../../context/CurrentUserContext';
import Input from '../../../components/forms/InputBase';
import JSONInput from '../../../components/forms/JSONInput';

export default function Form({ onBackButtonClick }) {
  const { currentEntity } = useMe();
  const { name, metadata, icon_url } = currentEntity;
  const form = useForm({ defaultValues: { name, icon_url, metadata } });
  const queryClient = useQueryClient();

  const { privateAxios } = useContext(AxiosContext);

  function update(callbacks = {}) {
    const mutation = async (params) => {
      const { data: { data } } = await privateAxios.patch('/entity', params);

      return data;
    };

    return useMutation({
      mutationFn: mutation,
      ...callbacks,
    });
  }

  const { isLoading: isSubmitting, mutate } = update(
    {
      onSuccess: () => {
        queryClient.invalidateQueries('me');
        onBackButtonClick();
      },
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const onSubmit = form.handleSubmit((entity) => mutate({ entity }));

  return (
    <div className="bg-white">
      <div className="px-4 sm:px-6 mb-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Edit Entity</h3>
        <p className="max-w-2xl text-sm text-gray-500">Edit name and icon for this entity.</p>
      </div>
      <form onSubmit={onSubmit}>
        <div className="mx-auto max-w-3xl lg:max-w-7xl">
          <div className="w-full bg-white px-10 border-t border-gray-300 rounded">
            <div className="mt-4">
              <Input
                form={form}
                label="Name"
                name="name"
                placeholder="Name..."
                className="mt-4"
              />
            </div>
            <div className="mt-4">
              <Input
                form={form}
                label="Icon URL"
                name="icon_url"
                placeholder="Icon URL..."
              />
            </div>
            <div className="mt-4">
              <JSONInput
                form={form}
                label="Metadata"
                name="metadata"
                placeholder="Metadata..."
              />
            </div>
            <Footer
              backButtonText="Back"
              onBackButtonClick={onBackButtonClick}
              submitButtonText="Submit"
              submitButtonDisabled={isSubmitting}
              submitButtonType="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
