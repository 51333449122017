/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/24/outline';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import JsonDisplay from '../../../../components/JsonDisplay';
import { formatDateTime } from '../../../../helpers/DateHelpers';

export default function InfoCard({ connection, connectionTemplate = null }) {
  const navigate = useNavigate();
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900 flex">
              General Information
            </h2>
            <button
              key="signature"
              type="button"
              onClick={() => navigate('edit')}
              className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
            >
              <PencilIcon className="h-3 w-3 mr-2 mt-0.5 text-indigo-600" aria-hidden="true" />
              <p>Edit</p>
            </button>
          </div>
          <div className="mx-auto mt-6 px-4 sm:px-6 lg:px-8 mb-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{connection.name}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Connection Template</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {connectionTemplate?.name || 'N/A'}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Connection Type</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {connection.api ? 'Web App' : null}
                  {connection.database ? 'Database' : null}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Last Update</dt>
                <dd className="mt-1 text-sm text-gray-900">{formatDateTime(connection.updated_at)}</dd>
              </div>
              {/* <div className={classNames(connection.description ? 'sm:col-span-2' : 'col-span-1')}>
                <dt className="text-sm font-medium text-gray-500">Description</dt>
                <dd className="mt-1 text-sm text-gray-900">{connection.description || 'N/A'}</dd>
              </div> */}
              <div className="col-span-2">
                <dt className="text-sm font-medium text-gray-500">Metadata</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <JsonDisplay valueObject={connection.metadata} />
                </dd>
              </div>
              <div className="col-span-2">
                <dt className="text-sm font-medium text-gray-500">Variables</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <JsonDisplay valueObject={connection.variables} />
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
}

InfoCard.propTypes = {
  connection: ConnectionShape.isRequired,
  connectionTemplate: ConnectionShape,
};
