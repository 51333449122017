/* eslint-disable max-len */
import React from 'react';
import ErrorBoundary from '../../../components/ErrorBoundary';
import ConnectionShape from '../../../shapes/ConnectionShape';
import JsonDisplay from '../../../components/JsonDisplay';

const formatConnectionJsonForDisplay = (connection) => {
  const { id, name, template, parent_id, icon_url, secrets, variables, metadata, api, database, webhook } = connection;
  return { id, name, template, parent_id, icon_url, secrets, variables, metadata, api, database, webhook };
};

export default function JSONConfig({ connection }) {
  const connectionConfigData = formatConnectionJsonForDisplay(connection);
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              JSON Configuration
            </h2>
          </div>
          <JsonDisplay valueObject={connectionConfigData} />
        </div>
      </ErrorBoundary>
    </section>
  );
}

JSONConfig.propTypes = {
  connection: ConnectionShape.isRequired,
};
