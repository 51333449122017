/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { listTemplateVariables, extractValue } from '../../helpers/TemplateHelpers';
import UrlVariable from './UrlVariable';
import ConnectionShape from '../../shapes/ConnectionShape';

export default function UrlPreview({
  url: initialUrl = null,
  event = {}, connection = null, method = null, config = null, connectionTemplate = null, extractConfig = null,
}) {
  const url = config?.hideConnectionUrl ? initialUrl?.replace('{{destination_connection.api.url}}', '') : initialUrl;
  const preview = url || '';

  // Generate a list of all template variables
  const list = listTemplateVariables(url);

  // Take our list of template variables and fetch the value
  const cxn = connection || connectionTemplate;
  const valueList = list?.map((v) => extractValue(v, event, cxn, extractConfig) || []);

  let comparisonString = preview;
  const displayPreview = valueList?.map((v, i) => {
    const split = comparisonString.split(`{{${v.keyPath}}}`);
    const start = split[0];
    const end = split[1];
    const value = <UrlVariable key={v} variable={v} config={config} index={i} />;
    if (i !== valueList.length - 1) {
      comparisonString = end;
      return [start, value];
    }
    return [start, value, end];
  });

  return (
    <span className="flex text-sm">
      <span className="text-teal-500 font-semibold text-xs mr-2">
        {method?.toUpperCase()}
      </span>
      <span className="truncate flex text-gray-500 text-xs">
        {displayPreview || comparisonString}
      </span>
    </span>
  );
}

UrlPreview.propTypes = {
  url: PropTypes.string,
  method: PropTypes.string,
  event: PropTypes.shape(),
  connection: ConnectionShape,
  connectionTemplate: ConnectionShape,
  config: PropTypes.shape(),
  extractConfig: PropTypes.shape(),
};
