import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import useClient from '../../../hooks/useClient';
import Form from '../Form';
import mapErrorsToForm from '../../../helpers/FormHelpers';

export default function CreatePage() {
  const form = useForm();
  const client = useClient('shapes');
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { isLoading: isSubmitting, mutate } = client.create({
    onSuccess: ({ id }) => {
      queryClient.invalidateQueries('shapes');
      navigate(-1);
    },
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit((shape) => mutate({ shape }));

  return (
    <Form
      description="Create a new data shape to describe the schema of a JSON objectß."
      form={form}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      title="New Data Shape Form"
    />
  );
}
