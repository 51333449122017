/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import LinkButton from '../../../components/buttons/LinkButton';

export default function ResourcesTable({ resources }) {
  return (
    <>
      {/* Projects list (only on smallest breakpoint) */}
      <div className="sm:hidden">
        <div className="px-4 sm:px-6">
          <h2 className="text-sm font-medium text-gray-900 mt-2">Requests</h2>
        </div>
        <ul className="mt-3 divide-y divide-gray-100 border-t border-gray-200">
          {resources.map((r) => (
            <li key={r.name}>
              <button
                type="button"
                aria-label="Display Log"
                className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span className="flex items-center space-x-3 truncate">
                  <span className="truncate text-xs font-medium leading-6">
                    <span className="font-heavy text-indigo-700 mr-1">
                      {r.topic}
                    </span>
                    <span className="truncate font-normal text-gray-500">
                      {/* {r.body.url} */}
                    </span>
                  </span>
                </span>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Projects table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="inline-block min-w-full border-b border-gray-200 align-middle py-4">
          <table className="min-w-full  border border-gray-200">
            <thead>
              <tr className="border-t border-gray-200">
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Name
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Path
                </th>
                <th
                  scope="col"
                  className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Topics
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900"
                />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {resources.map((r) => (
                <tr key={r.name}>
                  <td className="px-6 py-3 text-xs font-medium text-gray-900">
                    {r.name}
                  </td>
                  <td className="px-6 py-3 text-xs font-medium text-gray-500">
                    {r.path}
                  </td>
                  <td className="hidden whitespace-nowrap px-6 py-3 text-left text-xs text-gray-500 md:table-cell">
                    {r.filters?.topic.length}
                  </td>
                  <td className="whitespace-nowrap px-6 py-3 text-right text-sm font-medium">
                    <LinkButton className="text-xs" buttonText="Details" path={`/resources/${r.id}`} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

ResourcesTable.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
