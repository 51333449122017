/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import useClient from '../../../../hooks/useClient';
import Modal from '../../../../components/Modal';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Loading from '../../../../components/Loading';
import { formatTime } from '../../../../helpers/DateHelpers';
import LinkButton from '../../../../components/buttons/LinkButton';
import LogDetailModalBody from '../../../Action/ShowPage/LogDetailModalBody';
import RequestResponseStatusBadge from '../../../../components/Badges/RequestResponseStatusBadge';
import LogShape from '../../../../shapes/LogShape';

export default function ApiHistory({ connection }) {
  const [currentLog, setCurrentLog] = useState(null);

  const [date, setDate] = useState(DateTime.now());
  const isoDate = date.toISODate();

  const startDate = date.startOf('day').toISO();
  const endDate = date.endOf('day').toISO();

  // const startOfDay = DateTime.now().set({ second: 0, millisecond: 0, hour: 0, minute: 0 });
  // const endOfDay = DateTime.now().set({ second: 0, millisecond: 0, hour: 23, minute: 59 });
  // const [startDate, setStartDate] = useState(startOfDay.toISO({ includeOffset: false }));
  // const [endDate, setEndDate] = useState(endOfDay.toISO({ includeOffset: false }));

  const { data: logs, isLoading: isLoadingLogs } = useClient('logs').list({
    records: { destination_connection_id: connection.id },
    // page_size: 100,
    topic: 'action.processed',
    start_date: startDate,
    end_date: endDate,
  },
  { enabled: !connection.template },
  );

  if (connection.template) return null;

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              API Request History
            </h2>
            <div className="flex">
              <button
                type="button"
                aria-label="Previous Day"
                onClick={() => {
                  const prevDay = date.minus({ days: 1 });
                  setDate(prevDay);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
              </button>
              <input
                className="h-7 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block text-xs border-gray-300 rounded-sm mx-2"
                type="date"
                value={isoDate}
                onChange={(e) => {
                  e.preventDefault();
                  setDate(DateTime.fromISO(e.target.value));
                }}
              />
              <button
                type="button"
                aria-label="Next Day"
                onClick={() => {
                  const nextDay = date.plus({ days: 1 });
                  setDate(nextDay);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
              </button>
            </div>
            {/* <div className="flex">
              <input
                className="h-7 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs border-gray-300 rounded-sm mx-2"
                type="datetime-local"
                value={startDate}
                onChange={(e) => {
                  e.preventDefault();
                  setStartDate(e.target.value);
                }}
              />
              -
              <input
                className="h-7 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-xs border-gray-300 rounded-sm mx-2"
                type="datetime-local"
                value={endDate}
                onChange={(e) => {
                  e.preventDefault();
                  setEndDate(e.target.value);
                }}
              />
            </div> */}
          </div>
          <Modal maxWidth="sm:max-w-5xl sm:w-full" open={!!currentLog} onClose={() => setCurrentLog(null)}>
            <LogDetailModalBody log={currentLog} onClose={() => setCurrentLog(null)} />
          </Modal>
          {
            isLoadingLogs
              ? (<Loading />)
              : (
                logs.length
                  ? <Table logs={logs} setCurrentLog={setCurrentLog} />
                  : (
                    <p className="m-4 max-w-2xl text-sm text-gray-500">
                      There are currently no API Request for this connection on this date!
                    </p>
                  )
              )
          }
        </div>
      </ErrorBoundary>
    </section>
  );
}

ApiHistory.propTypes = {
  connection: ConnectionShape.isRequired,
};

export function Table({ logs, setCurrentLog }) {
  return (
    <>
      {/* Projects list (only on smallest breakpoint) */}
      <div className="sm:hidden">
        <div className="px-4 sm:px-6">
          <h2 className="text-sm font-medium text-gray-900 mt-2">Requests</h2>
        </div>
        <ul className="mt-3 divide-y divide-gray-100 border-t border-gray-200">
          {logs.map((log) => (
            <li key={log.id}>
              <button
                type="button"
                aria-label="Display Log"
                className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentLog(log);
                }}
              >
                <span className="flex items-center space-x-3 truncate">
                  <span className="truncate text-xs font-medium leading-6">
                    <span className="font-heavy text-indigo-700 mr-1">
                      {log.body.method}
                    </span>
                    <span className="truncate font-normal text-gray-500">
                      {log.body.url}
                    </span>
                  </span>
                </span>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Projects table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="inline-block min-w-full border-b border-gray-200 align-middle p-4">
          <table className="min-w-full  border border-gray-200">
            <thead>
              <tr className="border-t border-gray-200">
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  <span className="lg:pl-2">Method + URL</span>
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Timestamp
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900"
                />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {logs.map((log) => {
                const url = new URL(log.body.url);

                // Get the pathname and search parameters
                const { pathname, search } = url;

                // Combine them to create the URL without the base
                const urlWithoutBase = pathname + search;

                return (
                  <tr key={log.id}>
                    <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-900">
                      <div className="flex flex-wrap items-center space-x-3 lg:pl-2">
                        <span className="truncate">
                          <span>
                            <span className="font-heavy text-indigo-700 mr-2">
                              {log.body.method}
                            </span>
                            <span className="font-normal text-gray-500">
                              {urlWithoutBase}
                            </span>
                          </span>
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-3 text-xs font-medium text-gray-500">
                      <RequestResponseStatusBadge httpStatus={log.body.http_status} />
                    </td>
                    <td className="hidden whitespace-nowrap px-6 py-3 text-right text-xs text-gray-500 md:table-cell">
                      {formatTime(log.inserted_at)}
                    </td>
                    <td className="whitespace-nowrap px-6 py-3 text-right text-sm font-medium">
                      <LinkButton className="text-xs" buttonText="Details" onClick={() => setCurrentLog(log)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>

  );
}

Table.propTypes = {
  setCurrentLog: PropTypes.func.isRequired,
  logs: PropTypes.arrayOf(LogShape).isRequired,
};
