/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import Input from '../../../../components/forms/Input';
import FormShape from '../../../../shapes/FormShape';
import ComboBox from '../../../../components/forms/ComboBox';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import EventShape from '../../../../shapes/EventShape';
import UrlPreview from '../../../../components/UrlPreview';

const methods = [
  { id: 'post', name: 'POST' },
  { id: 'put', name: 'PUT' },
  { id: 'patch', name: 'PATCH' },
  { id: 'get', name: 'GET' },
  { id: 'delete', name: 'DELETE' },
];

export default function MethodPathCard({ form, destinationConnection, event }) {
  return (
    <div className="w-full bg-white mb-4">
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-5">
        <div className="col-span-5 lg:col-span-1">
          <ComboBox
            form={form}
            label="Method"
            name="configuration.method"
            options={methods}
            valueKey="id"
            labelKey="name"
            required
          />
        </div>
        <div className="col-span-5 lg:col-span-4">
          <Input
            form={form}
            placeholder="URL..."
            label="URL"
            name="configuration.url"
            type="text"
            required
            helperText={(
              !!form.watch('configuration.method') && !!form.watch('configuration.url')
                ? (
                  <div className="mt-2">
                    <UrlPreview
                      method={form.watch('configuration.method')}
                      url={form.watch('configuration.url')}
                      event={event}
                      connection={destinationConnection}
                    />
                  </div>
                )
                : null
            )}
          />
        </div>
      </div>
    </div>
  );
}

MethodPathCard.propTypes = {
  form: FormShape.isRequired,
  destinationConnection: ConnectionShape,
  event: EventShape,
};

MethodPathCard.defaultProps = {
  event: null,
  destinationConnection: null,
};
