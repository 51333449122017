import LogRocket from 'logrocket';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import AppRouter from './AppRouter';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';
import { AxiosProvider } from './context/AxiosContext';
import { ErrorProvider } from './context/ErrorContext';
import { FlashProvider } from './context/FlashContext';
import { SocketProvider } from './context/SocketContext';
import { CurrentUserProvider } from './context/CurrentUserContext';
import { enableSentry, enableLogRocket } from './config';

if (process.env.NODE_ENV === 'production' || enableLogRocket) {
  LogRocket.init('j0bt6d/partybus', {
    dom: {
      inputSanitizer: true,
      textSanitizer: true,
    },
  });
}

if (process.env.NODE_ENV === 'production' || enableSentry) {
  Sentry.init({
    dsn: 'https://afdd151832a64d68bd68a0335f96835b@o912508.ingest.sentry.io/5849678',
    environment: process.env.NODE_ENV,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const queryClient = new QueryClient();
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AxiosProvider>
          <SocketProvider>
            <CurrentUserProvider>
              <FlashProvider>
                <ErrorProvider>
                  <BrowserRouter>
                    <AppRouter />
                  </BrowserRouter>
                </ErrorProvider>
              </FlashProvider>
            </CurrentUserProvider>
          </SocketProvider>
        </AxiosProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
