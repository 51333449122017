import React from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';
import { useNavigate, Link } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import ConnectionShape from '../../../../shapes/ConnectionShape';

export default function ServerInfoCard({ connection }) {
  const navigate = useNavigate();
  // We end up at this card for connections that have no connection.api config
  if (connection.database) {
    return (
      <section aria-labelledby="action-information-title">
        <ErrorBoundary>
          <div className="bg-white border border-gray-300 rounded">
            <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
              <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                Server Information
              </h2>
              <button
                key="signature"
                type="button"
                onClick={() => navigate('edit-auth')}
                className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
              >
                <PencilIcon className="h-3 w-3 mr-2 mt-0.5 text-indigo-600" aria-hidden="true" />
                <p>Edit</p>
              </button>
            </div>
            <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8 mb-6">
              <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Name</dt>
                  <dd className="mt-1 text-sm text-gray-900">{connection.database.url}</dd>
                </div>
                <div className="sm:col-span-1">
                  <dt className="text-sm font-medium text-gray-500">Type</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {connection.database?.type}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </ErrorBoundary>
      </section>
    );
  }

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Server Information
            </h2>
          </div>
          <div className="sm:col-span-2 p-5">
            <dd className="mt-1 text-sm text-gray-900">
              <div className="rounded-md bg-blue-50 border border-blue-300 p-4">
                <div className="flex space-x-2">
                  <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
                  <h3 className="text-sm font-medium text-blue-800">API Config Pending</h3>
                </div>
                <div className="flex text-sm text-blue-700 space-x-1">
                  <p>This connection has not been configured to connect to an API.</p>
                  <p>Click</p>
                  <Link
                    className="text-indigo-700 font-bold"
                    to="configure-auth"
                  >
                    here
                  </Link>
                  <p>to configure.</p>
                </div>
              </div>
            </dd>
          </div>
        </div>

      </ErrorBoundary>
    </section>
  );
}

ServerInfoCard.propTypes = {
  connection: ConnectionShape.isRequired,
};
