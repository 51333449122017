/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useForm } from 'react-hook-form';
import Header from '../../../components/layout/PageHeader';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../shapes/ConnectionShape';
import BaseForm from '../Form/Base/Edit';

export default function ConnectionForm({ connection }) {
  const form = useForm({ defaultValues: connection });

  return (
    <>
      <Header
        name={`Edit ${form.watch('name')}`}
        description={`Edit your existing ${form.watch('name')} connection.`}
        avatar={connectionAvatar(form.watch(), { size: 'lg' })}
      />

      <BaseForm connection={connection} editMode />
    </>
  );
}

ConnectionForm.propTypes = {
  connection: ConnectionShape.isRequired,
};
