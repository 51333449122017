/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import TagsInput from '../../../../components/forms/TagsInput';
import FormShape from '../../../../shapes/FormShape';
import Input from '../../../../components/forms/InputBase';
import LinkButton from '../../../../components/buttons/LinkButton';
import RadioInput from '../../../../components/forms/RadioInput';

export default function CallbackEventForm({ form }) {
  const { getValues } = form;
  const [display, setDisplay] = useState(getValues('after_action.topic'));

  if (display) {
    return (
      <div className="pt-6">
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Response Event</h3>
          <span className="flex mt-1 text-sm text-gray-500">
            <p className="mr-2">
              Add an optional response event with the response body from this Action.
            </p>
            <LinkButton
              buttonText="Remove Response Event"
              onClick={() => {
                setDisplay(false);
                form.setValue('after_action', null);
              }}
            />
          </span>
        </div>
        <div className="mt-4 border p-4 rounded-md border-gray-300">
          <div className="w-full">
            <Input
              form={form}
              label="Topic"
              name="after_action.topic"
              required
              placeholder="Topic..."
              helperText="Add a topic to publish response events."
              inputClass="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
          <div className="w-full mt-6">
            <RadioInput
              form={form}
              label="Event Branching Type"
              name="after_action.type"
              required
              helperText="Will this after action iterate over a list in order to branch into many events?"
              inputClass="flex space-x-6 w-full focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              options={[
                { value: 'single', label: 'Produce a single event' },
                { value: 'multiple', label: 'Branch into multiple events' },
              ]}
            />
          </div>
          <div className="w-full mt-6">
            <TagsInput
              label="Data Path"
              name="after_action.response_key"
              form={form}
              helperText="Add an optional list of keys to 'dig' for the desired data body."
            />
          </div>
          <div className="w-full mt-6">
            <TagsInput
              label="Idempotency Keys"
              name="after_action.idempotency_keys"
              form={form}
              helperText="Provide a list of data keys to prevent dulicate events. We'll start from the data path above if utilized."
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-6">
      <label htmlFor="matcher" className="block text-sm font-medium text-gray-700">
        Add Optional Response Event?
      </label>
      <div className="mt-2 border border-gray-300 rounded-md p-2">
        <span className="mt-2 text-sm text-gray-500">
          Turn a successful response from this API request into an event.
          <button type="button" className="ml-1 text-indigo-700" onClick={() => setDisplay(true)}>
            Add Response Event
          </button>
        </span>
      </div>
    </div>
  );
}

CallbackEventForm.propTypes = {
  form: FormShape.isRequired,
};
