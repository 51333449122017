/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

export default function BaseBadge({ message, color, icon = null }) {
  return (
    <span
      style={{ fontSize: 9 }}
      className={`mx-2 h-4 inline-flex items-center gap-x-1.5 rounded-sm bg-${color}-100 px-1.5 font-medium border border-${color}-500 text-${color}-600`}
    >
      {icon}
      {/* <svg className={`size-1.5 fill-${color}-500`} viewBox="0 0 6 6" aria-hidden="true">
        <circle cx="3" cy="3" r="3" />
      </svg> */}
      {message}
    </span>
  );
}

BaseBadge.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
  color: PropTypes.string.isRequired,
  icon: PropTypes.element,
};
