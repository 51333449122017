import React from 'react';
import ApiCard from './ApiCard';
import ApiHistory from './ApiHistoryCard';
import OAuthSuccessPreviewCard from './OAuthSuccessPreviewCard';
import ConnectionShape from '../../../../shapes/ConnectionShape';

export default function ApiCards({ connection }) {
  return (
    <>
      <ApiCard connection={connection} />
      <ApiHistory connection={connection} />
      <OAuthSuccessPreviewCard connection={connection} />
    </>
  );
};

ApiCards.propTypes = {
  connection: ConnectionShape.isRequired,
};