import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Loading from '../../../components/Loading';
import useMe from '../../../context/CurrentUserContext';
import Workflow from './Workflow';
import LogShape from '../../../shapes/LogShape';
import EventShape from '../../../shapes/EventShape';
export default function ThreadTree({ threadLogs, isLoadingLogs, event }) {
  const { workflows } = useMe();

  const threadActionLogs = threadLogs.filter((l) => l.topic === 'action.evaluated' && l.body.result === true);
  const threadWorkflowIds = _.uniq(threadActionLogs.map((l) => l.records.workflow_id));
  const threadWorkflows = workflows.filter((w) => threadWorkflowIds.includes(w.id));

  if (isLoadingLogs) {
    return <Loading className="flex items-center justify-center w-full h-12 my-4" />;
  }
  return threadWorkflows.map((w) => (
    <Workflow key={w.id} workflow={w} threadLogs={threadLogs} event={event} />
  ));
}

ThreadTree.propTypes = {
  threadLogs: PropTypes.arrayOf(LogShape).isRequired,
  isLoadingLogs: PropTypes.bool.isRequired,
  event: EventShape.isRequired,
};
