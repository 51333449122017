/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CodeBracketSquareIcon, ReceiptRefundIcon, ListBulletIcon } from '@heroicons/react/24/solid';
import LogShape from '../../../../shapes/LogShape';
import Exception from './Exception';
import GeneralInfo from './GeneralInfo';
import RequestBody from './RequestBody';
import RequestHeaders from './RequestHeaders';
import RequestResponse from './RequestResponse';
import RelatedRecords from './RelatedRecords';

export default function LogDetailBody({ backText = 'Back to Detail Page', log = null, onClose }) {
  const [activeTab, setActiveTab] = useState(0);
  const activeClass = 'hover:cursor-pointer inline-flex p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active';
  const inactiveClass = 'hover:cursor-pointer inline-flex p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group';

  if (!log) return null;
  return (
    <div className="bg-white">
      <div className="px-4 pb-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">Log Information</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Request body and response data for this action request.</p>
      </div>
      <div className="border-t border-gray-300">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
          <li className="mr-2">
            <button
              type="button"
              onClick={() => setActiveTab(0)}
              className={classNames(activeTab === 0 ? activeClass : inactiveClass)}
            >
              <ReceiptRefundIcon className="h-5 w-5 mr-1" />
              Request Response
            </button>
          </li>
          <li className="mr-2">
            <button
              type="button"
              onClick={() => setActiveTab(1)}
              className={classNames(activeTab === 1 ? activeClass : inactiveClass)}
            >
              {' '}
              <CodeBracketSquareIcon className="h-5 w-5 mr-1" />
              Request Data
            </button>
          </li>
          <li className="mr-2">
            <button
              type="button"
              onClick={() => setActiveTab(2)}
              className={classNames(activeTab === 2 ? activeClass : inactiveClass)}
            >
              {' '}
              <ListBulletIcon className="h-5 w-5 mr-1" />
              Related Records
            </button>
          </li>
        </ul>
      </div>

      <div className="border-t border-gray-300 px-4 py-5 sm:px-6 max-h-screen overflow-scroll">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
          <GeneralInfo log={log} />
          { log.body.record_type === 'request' && activeTab === 0 ? (<RequestResponse log={log} />) : null}
          {
            log.body.record_type === 'request' && activeTab === 1
              ? (
                <>
                  <RequestHeaders log={log} />
                  <RequestBody log={log} />
                </>
              ) : null
          }
          { log.body.record_type === 'request' && activeTab === 2 ? (<RelatedRecords log={log} />) : null}

          { log.body.record_type === 'exception' ? (<Exception log={log} />) : null }
        </dl>
      </div>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse px-6">
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
          onClick={onClose}
        >
          {backText}
        </button>
      </div>
    </div>
  );
}

LogDetailBody.propTypes = {
  backText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  log: LogShape,
};
