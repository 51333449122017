/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { IoCaretDownOutline, IoCaretUpOutline } from 'react-icons/io5';
import DateInput from '../forms/DateInput';
import IndexClientShape from '../../shapes/IndexClientShape';
import LinkButton from '../buttons/LinkButton';

const now = DateTime.now();
const startOfDay = now.startOf('day').toISO();
const startOfWeek = now.startOf('week').toISO();
const startOfMonth = now.startOf('month').toISO();
const startOfSevenDays = now.minus({ days: 6 }).startOf('day').toISO();
const startOfFourteenDays = now.minus({ days: 13 }).startOf('day').toISO();
const startOfTwentyOneDays = now.minus({ days: 20 }).startOf('day').toISO();
const endValue = now.plus({ days: 1 }).startOf('day').toISO();

const timeOptions = [
  { label: 'Today', startValue: startOfDay, endValue },
  { label: 'This Week', startValue: startOfWeek, endValue },
  { label: 'This Month', startValue: startOfMonth, endValue },
];

const timeOptions2 = [
  { label: 'Last 7 days', startValue: startOfSevenDays, endValue },
  { label: 'Last 14 days', startValue: startOfFourteenDays, endValue },
  { label: 'Last 21 days', startValue: startOfTwentyOneDays, endValue },
];

export default function DateFilter({ indexClient }) {
  const [expanded, setExpanded] = useState(false);
  const { onSubmit } = indexClient;
  return (
    <div className="w-full p-3 border-gray-300">
      <div className="flex w-full justify-between">
        <div className="xl:flex w-full">
          <div className="flex justify-between">
            <label htmlFor="date-range-button" className="w-36 block text-sm font-medium text-gray-700 mr-8">
              Filter by Date Range:
            </label>
            <span className="block lg:hidden">
              <LinkButton
                id="date-range-button"
                buttonText={expanded
                  ? (
                    <span className="flex">
                      Reduce
                      <IoCaretUpOutline className="m-1" />
                    </span>
                  )
                  : (
                    <span className="flex">
                      Expand
                      <IoCaretDownOutline className="m-1" />
                    </span>
                  )}
                onClick={() => setExpanded(!expanded)}
              />
            </span>
          </div>
          <div className="flex">
            <nav className="2xl:flex 2xl:space-x-4 mr-16 mt-4 xl:mt-0">
              {timeOptions.map((tab) => (
                <div key={tab.label}>
                  <button
                    type="button"
                    onClick={() => {
                      indexClient.filterForm.setValue('start_date', tab.startValue);
                      indexClient.filterForm.setValue('end_date', tab.endValue);
                      indexClient.onSubmit();
                    }}
                    className={classNames(
                      'rounded-sm text-xs font-medium px-2 w-32 border border-gray-300 h-6 mt-2 2xl:mt-0',
                      tab.startValue === indexClient.filterForm.watch('start_date') && tab.endValue === indexClient.filterForm.watch('end_date')
                        ? 'bg-indigo-50 text-indigo-700 border border-indigo-700'
                        : 'text-gray-500 hover:text-gray-700 ',
                    )}
                    aria-current={tab.startValue === indexClient.filterForm.watch('start_date') && tab.endValue === indexClient.filterForm.watch('end_date') ? 'page' : undefined}
                  >
                    {tab.label}
                  </button>
                </div>
              ))}
            </nav>
            <nav className="2xl:flex 2xl:space-x-4 mt-4 xl:mt-0">
              {timeOptions2.map((tab) => (
                <div key={tab.label}>
                  <button
                    type="button"
                    onClick={() => {
                      indexClient.filterForm.setValue('start_date', tab.startValue);
                      indexClient.filterForm.setValue('end_date', tab.endValue);
                      indexClient.onSubmit();
                    }}
                    className={classNames(
                      'rounded-sm text-xs font-medium px-2 w-32 h-6 border border-gray-300 mt-2 2xl:mt-0',
                      tab.startValue === indexClient.filterForm.watch('start_date') && tab.endValue === indexClient.filterForm.watch('end_date')
                        ? 'bg-indigo-50 text-indigo-700 border border-indigo-700'
                        : 'text-gray-500 hover:text-gray-700 ',
                    )}
                    aria-current={tab.startValue === indexClient.filterForm.watch('start_date') && tab.endValue === indexClient.filterForm.watch('end_date') ? 'page' : undefined}
                  >
                    {tab.label}
                  </button>
                </div>
              ))}
            </nav>
          </div>
        </div>
        <span className="hidden lg:block">
          <LinkButton
            buttonText={expanded
              ? (
                <span className="flex">
                  Reduce
                  <IoCaretUpOutline className="m-1" />
                </span>
              )
              : (
                <span className="flex">
                  Expand
                  <IoCaretDownOutline className="m-1" />
                </span>
              )}
            onClick={() => setExpanded(!expanded)}
          />
        </span>
      </div>
      {
        expanded
          ? (
            <div className="flex mt-8 w-full justify-end">
              <div>
                <DateInput
                  inputClass="h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  form={indexClient.filterForm}
                  type="datetime-local"
                  name="start_date"
                  onBlur={onSubmit}
                />
              </div>
              <div>
                <DateInput
                  inputClass="h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  form={indexClient.filterForm}
                  type="datetime-local"
                  name="end_date"
                  onBlur={onSubmit}
                />
              </div>
            </div>
          )
          : null
      }
    </div>
  );
}

DateFilter.propTypes = {
  indexClient: IndexClientShape.isRequired,
};
