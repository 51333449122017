/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LinkIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import Header from '../../../components/layout/PageHeader';
import ProgressBar from '../Form/ProgressBar';
import Footer from '../../../components/forms/Footer';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import { formatHeaders } from '../../../helpers/ConnectionHelpers';
import RadioButtonGroup from '../../../components/forms/RadioInputGroup';
import ScopeInputWrapper from '../Form/SubForms/AuthSubForm/ScopeInputWrapper';
import ApiKeySubForm from '../Form/SubForms/AuthSubForm/ApiKeySubForm';
import BasicSubForm from '../Form/SubForms/AuthSubForm/BasicSubForm';
import BearerSubForm from '../Form/SubForms/AuthSubForm/BearerSubForm';
import OAuthTwoSubForm from '../Form/SubForms/AuthSubForm/OAuthTwoSubForm';
import Input from '../../../components/forms/InputLockable';
import useClient from '../../../hooks/useClient';
import ConnectionShape from '../../../shapes/ConnectionShape';

function authDesc(recordType) {
  switch (recordType) {
    case 'basic':
      return 'Basic';
    case 'bearer':
      return 'Bearer';
    case 'oauth2':
      return 'OAuth2.0';
    case 'database':
      return 'Username/Password';
    default:
      return recordType;
  }
}

export default function ConfigureAuthForm({ connection, connectionTemplate, defaultValues }) {
  const form = useForm({ defaultValues });
  const navigate = useNavigate();

  const { isLoading: isSubmitting, mutate } = useClient('connections').update(
    connection?.id,
    {
      onSuccess: ({ id }) => navigate(`/connections/${id}`),
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const onSubmit = form.handleSubmit(
    (c) => {
      const formattedData = formatHeaders(c);
      console.log('submit')
      return mutate({ connection: formattedData });
    },
  );

  const serverType = form.watch('api') ? 'api' : form.watch('database') ? 'database' : null;
  return (
    <>
      <Header
        name="New Connection API Setup"
        description="Add a new Event Source or Action Destination"
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <ProgressBar currentStepIndex={2} />
      <form onSubmit={onSubmit}>
        <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
          <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10 pb-6">
            <div className="mt-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Server Setup
              </h3>
              <span className="mt-1 flex text-sm text-gray-500">
                Enter the URL and Auth details for
                <p className="font-semibold ml-1">
                  {' '}
                  {connection.name}
                  .
                </p>
              </span>
            </div>
            {!connectionTemplate
              ? (
                <div className="mt-6">
                  <RadioButtonGroup
                    label="Sever Type"
                    name="server_type"
                    valueOverride={serverType}
                    onChangeOverride={(v) => {
                      if (v === 'database') {
                        form.setValue('api', null);
                        form.setValue('database', {type: 'mssql'});
                      } else {
                        form.setValue('database', null);
                        form.setValue('api', {});
                      }
                    }}
                    options={[
                      { value: 'database', label: 'Database' },
                      { value: 'api', label: 'Web Application' },
                    ]}
                  />
                </div>
              ) : null}

            <div className="mt-4">
              {
                serverType === 'api'
                  ? (
                    <Input
                      displayLockButton={!!connectionTemplate?.api?.url}
                      defaultLocked={!!connectionTemplate?.api?.url}
                      form={form}
                      label="API URL"
                      name="api.url"
                      placeholder="Name..."
                      helperText="Add the API URL to reach this Web application's API."
                    />
                  )
                  : null
              }
              {
              serverType === 'database'
                ? (
                  <Input
                    displayLockButton={!!connectionTemplate?.database?.url}
                    defaultLocked={!!connectionTemplate?.database?.url}
                    form={form}
                    label="Database URL"
                    name="database.url"
                    placeholder="Name..."
                    helperText="Add the Database URL to reach this Database config. Include username and password as params if required."
                  />
                )
                : null
            }
            </div>
            <div className="mt-4">
              {
            (!connectionTemplate && serverType === 'api') || (!!connectionTemplate?.api && !connectionTemplate.api.authentication)
              ? (
                <div className="mb-6">
                  <RadioButtonGroup
                    label="Auth Strategy"
                    name="api.authentication.record_type"
                    valueOverride={form.watch('api.authentication.record_type')}
                    onChangeOverride={(v) => {
                      if (v === null) {
                        form.setValue('api.authentication.record_type', null);
                      } else {
                        form.setValue('api.authentication.record_type', v);
                      }
                    }}
                    options={[
                      { value: null, label: 'None' },
                      { value: 'api_key', label: 'API Key' },
                      { value: 'basic', label: 'Basic Auth' },
                      { value: 'bearer', label: 'Bearer Token' },
                      { value: 'oauth2', label: 'OAuth 2.0' },
                    ]}
                  />
                </div>
              )
              : null
          }
            </div>
            {
            form.watch('api.authentication.record_type') && form.watch('api.authentication.record_type') !== 'none'
              ? (
                <div className="mt-6 gap-y-6 gap-x-4">
                  <div className="border rounded-md p-4 md:col-span-2">
                    {
                      !connectionTemplate || (!!connectionTemplate?.api && !connectionTemplate.api.authentication)
                        ? null
                        : (
                          <span className="flex text-sm  text-gray-500">
                            <InformationCircleIcon className="text-indigo-500 h-5 w-5 mr-1 mb-4" />
                            <span className="flex">
                              <p className="font-medium mr-1 text-gray-700">{connectionTemplate?.name || form.watch('name')}</p>
                              {' '}
                              uses
                              {' '}
                              <p className="font-medium mx-1 text-gray-700">{authDesc(form.watch('api.authentication.record_type'))}</p>
                              {' '}
                              authentication.
                            </span>
                          </span>
                        )
              }
                    <div className="grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
                      { form.watch('api.authentication.record_type') === 'api_key' ? <ApiKeySubForm form={form} /> : null }
                      { form.watch('api.authentication.record_type') === 'basic' ? <BasicSubForm form={form} /> : null }
                      { form.watch('api.authentication.record_type') === 'bearer' ? <BearerSubForm form={form} /> : null }
                      { form.watch('api.authentication.record_type') === 'oauth2' ? <OAuthTwoSubForm form={form} connectionTemplate={connectionTemplate} /> : null }
                    </div>
                    <ScopeInputWrapper form={form} connectionTemplate={connectionTemplate} editMode={false} />
                  </div>
                </div>
              )
              : null
          }
          </div>
          <Footer
            submitButtonText="Configure Auth"
            submitButtonDisabled={isSubmitting}
            submitButtonType="submit"
            onClickSecondary={() => navigate(`/connections/${connection.id}`)}
            secondaryText="Skip This Step"
          />
        </div>
      </form>
    </>
  );
}

ConfigureAuthForm.propTypes = {
  connection: ConnectionShape.isRequired,
  connectionTemplate: ConnectionShape,
  defaultValues: PropTypes.shape().isRequired,
};
