import React from 'react';

export default function TableHeader() {
  return (
    <thead className="bg-gray-50 border-b border-gray-300">
      <tr>
        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
          Connection/Topic
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
        >
          Description
        </th>
        <th
          scope="col"
          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
        >
          Status
        </th>
        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden xl:table-cell">
          Timestamp
        </th>
        <th scope="col" className="relative py-3.5 pl-3 pr-4">
          <span className="sr-only">Actions</span>
        </th>
      </tr>
    </thead>
  );
}
