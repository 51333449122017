import React from 'react';
import { useParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';
import Form from './Form';

export default function EditPage() {
  const { id } = useParams();
  const client = useClient('connections');
  const { data: connection, isFetching } = client.get(
    id,
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );

  if (isFetching) return <Loading />;

  return <Form connection={connection} />;
}
