/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { LinkIcon } from '@heroicons/react/24/outline';
import ConnectionTemplateSubForm from '../Form/ConnectionTemplateSubForm';
import Header from '../../../components/layout/PageHeader';
import ProgressBar from '../Form/ProgressBar';
import BaseForm from '../Form/Base/Create';
import useMe from '../../../context/CurrentUserContext';

export default function ConnectionForm() {
  const [connectionTemplate, setConnectionTemplate] = useState(null);
  const [skipUsingTemplate, setSkipUsingTemplate] = useState(null);

  const { connectionTemplates } = useMe();

  const onReset = () => {
    setConnectionTemplate(null);
    setSkipUsingTemplate(null);
  };

  return (
    <>
      <Header
        name="New Connection Form"
        description="Add a new Event Source or Action Destination"
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <ProgressBar currentStepIndex={connectionTemplate || skipUsingTemplate ? 1 : 0} />
      {
        skipUsingTemplate || connectionTemplate
          ? (
            <BaseForm
              connectionTemplate={connectionTemplate}
              skipUsingTemplate={skipUsingTemplate}
              onReset={onReset}
            />
          )
          : (
            <ConnectionTemplateSubForm
              templates={connectionTemplates}
              connectionTemplate={connectionTemplate}
              setConnectionTemplate={setConnectionTemplate}
              skipUsingTemplate={skipUsingTemplate}
              setSkipUsingTemplate={setSkipUsingTemplate}
            />
          )
      }

    </>
  );
}
