/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { BoltIcon, ExclamationTriangleIcon, PauseIcon } from '@heroicons/react/24/outline';
import activity from 'react-useanimations/lib/activity';
import _ from 'lodash';
import { Tooltip } from 'react-tooltip';
import EventShape from '../../shapes/EventShape';
import LoadingSpinner from '../LoadingSpinner';
import LogShape from '../../shapes/LogShape';
import BaseBadge from './Badge';
import useMe from '../../context/CurrentUserContext';

export default function StatusIcon({ event, events = [], threadLogs }) {
  const { actions } = useMe();
  if (event.status === 'noop') {
    return (
      <BaseBadge
        message="No Match"
        color="gray"
      />
    );
  }

  const eventStatusList = events?.map((e) => e.status) || [];
  const hasEventPending = eventStatusList.includes('pending');

  if (hasEventPending) {
    const pendingEvent = events.find((e) => e.status === 'pending');
    const pendingEventFailureLogs = threadLogs.filter((l) => l.records.event_id === pendingEvent.id && l.status === 'error' && l.topic === 'action.processed');
    const pendingActionIds = pendingEventFailureLogs.map((l) => l.records.action_id);
    const pendingActions = actions.filter((a) => pendingActionIds.includes(a.id)).map((a) => ({
      action: a,
      failureLogs: pendingEventFailureLogs.filter((l) => l.records.action_id === a.id),
      // eslint-disable-next-line radix
      maxRetryCount: parseInt(a.retry_strategy?.max_attempts || 5),

    }));
    const content = pendingActions.map((a) => (
      <div key={a.action.id}>
        <p>
          Pending Action:
          {' '}
          {a.action.name}
        </p>
        <p>
          Attempts:
          {' '}
          {a.failureLogs.length}
          /
          {a.maxRetryCount}
        </p>
      </div>
    ));

    const maxxedPendingActions = pendingActions.filter((pa) => pa.failureLogs.length >= pa.maxRetryCount);
    if (maxxedPendingActions.length) {
      return (
        <BaseBadge
          icon={<PauseIcon className="h-2 w-2 text-teal-700" />}
          message={(
            <span className="flex">
              <Tooltip id={pendingEvent.id} content={content} place="bottom" />
              <span data-tooltip-id={pendingEvent.id}>
                Failed
              </span>
            </span>
            )}
          color="red"
        />
      );
    }
    return (
      <BaseBadge
        icon={<LoadingSpinner animation={activity} strokeColor="#5F9EA0" />}
        message={(
          <span className="flex">
            <Tooltip id={pendingEvent.id} content={content} place="bottom" />
            <span data-tooltip-id={pendingEvent.id}>
              Pending...
            </span>
          </span>
        )}
        color="yellow"
      />
    );
  }

  const actionProcessedLogs = threadLogs.filter((l) => l.topic === 'action.processed');
  const failures = actionProcessedLogs.filter((l) => l.status === 'error');
  const failuresWithoutEventualSuccess = [];
  failures.forEach((l) => {
    const successResult = actionProcessedLogs.find((pl) => pl.records.action_id === l.records.action_id && pl.status === 'success');
    if (!successResult) failuresWithoutEventualSuccess.push(l.records.action_id);
    return null;
  });

  const uniqFailuresWithoutEventualSuccess = _.uniq(failuresWithoutEventualSuccess);
  if (uniqFailuresWithoutEventualSuccess.length) {
    return (
      <BaseBadge
        icon={<ExclamationTriangleIcon className="h-2 w-2 text-red-500" />}
        message="Resolved"
        color="red"
      />
    );
  }

  if (_.uniq(eventStatusList)[0] === 'resolved') {
    return (
      <BaseBadge
        icon={<BoltIcon className="h-2 w-2 text-teal-700" />}
        message="Resolved"
        color="teal"
      />
    );
  }

  return null;
}

StatusIcon.propTypes = {
  event: EventShape.isRequired,
  events: PropTypes.arrayOf(EventShape),
  threadLogs: PropTypes.arrayOf(LogShape).isRequired,
};
