/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
/* eslint-disable jsx-a11y/label-has-associated-control */
import ActionShape from '../../../../shapes/ActionShape';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import PredicateTree from './PredicateTree';

export default function PaginationCard({ action }) {
  if (!action.pagination) return null;
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Polling Pagination Information
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Details about the path and body of this actions request.</p>
          </div>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2 m-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Page Size</dt>
              <dd className="mt-1 text-sm text-gray-900">{action.pagination.page_size}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Max Pages</dt>
              <dd className="mt-1 text-sm text-gray-900">{action.pagination.maximum}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Pagination Logic</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {(action.body.type === 'constant' && action.body.value) ? `Always paginate until page ${action.pagination.maximum}.` : <PredicateTree predicate={action.pagination.body} />}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Branching</dt>
              <dd className="mt-1 text-sm text-gray-900">{action.after_action?.type === 'Single' ? 'Does not branch' : 'Branches into multiple events'}</dd>
            </div>
          </dl>
        </div>
      </ErrorBoundary>
    </section>
  );
}

PaginationCard.propTypes = {
  action: ActionShape.isRequired,
};
