/* eslint-disable max-len */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Header from '../../../components/layout/PageHeader';
import { formatHeaders, connectionAvatar } from '../../../helpers/ConnectionHelpers';
import ConnectionShape from '../../../shapes/ConnectionShape';
import Footer from '../../../components/forms/Footer';
import useClient from '../../../hooks/useClient';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import RadioButtonGroup from '../../../components/forms/RadioInputGroup';
import ScopeInputWrapper from '../Form/SubForms/AuthSubForm/ScopeInputWrapper';
import ApiKeySubForm from '../Form/SubForms/AuthSubForm/ApiKeySubForm';
import BasicSubForm from '../Form/SubForms/AuthSubForm/BasicSubForm';
import BearerSubForm from '../Form/SubForms/AuthSubForm/BearerSubForm';
import OAuthTwoSubForm from '../Form/SubForms/AuthSubForm/OAuthTwoSubForm';
import Input from '../../../components/forms/InputLockable';

function authDesc(recordType) {
  switch (recordType) {
    case 'basic':
      return 'Basic';
    case 'bearer':
      return 'Bearer';
    case 'oauth2':
      return 'OAuth2.0';
    case 'database':
      return 'Username/Password';
    default:
      return recordType;
  }
}

export default function ConnectionForm({ connection }) {
  const form = useForm({ defaultValues: connection });
  const navigate = useNavigate();
  const client = useClient('connections');

  const { isLoading: isSubmitting, mutate } = client.update(
    connection.id,
    {
      onSuccess: ({ id }) => navigate(`/connections/${id}`),
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const onSubmit = form.handleSubmit(
    (c) => {
      const formattedData = formatHeaders(c);
      return mutate({ connection: formattedData });
    },
  );
  return (
    <>
      <Header
        name={`Edit ${form.watch('name')}`}
        description={`Edit your existing ${form.watch('name')} connection.`}
        avatar={connectionAvatar(form.watch(), { size: 'lg' })}
      />
      <form onSubmit={onSubmit}>
        <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
          <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10 pb-6">
            <div className="mt-6">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Server Setup
              </h3>
              <span className="mt-1 flex text-sm text-gray-500">
                Enter the URL and Auth details for
                <p className="font-semibold ml-1">
                  {' '}
                  {connection.name}
                  .
                </p>
              </span>
            </div>
            <div className="mt-4">
              {
                form.watch('api')
                  ? (
                    <Input
                      form={form}
                      label="API URL"
                      name="api.url"
                      placeholder="Name..."
                      helperText="Add the API URL to reach this Web application's API."
                    />
                  )
                  : (
                    <Input
                      form={form}
                      label="Database URL"
                      name="database.url"
                      placeholder="Name..."
                      helperText="Add the Database URL to reach this Database config. Include username and password as params if required."
                    />
                  )
              }

            </div>
            {
              form.watch('api')
                ? (
                  <div className="mt-4 mb-6">
                    <RadioButtonGroup
                      label="Auth Strategy"
                      name="api.authentication.record_type"
                      valueOverride={form.watch('api.authentication.record_type')}
                      onChangeOverride={(v) => {
                        if (v === null) {
                          form.setValue('api.authentication.record_type', null);
                        } else {
                          form.setValue('api.authentication.record_type', v);
                        }
                      }}
                      options={[
                        { value: null, label: 'None' },
                        { value: 'api_key', label: 'API Key' },
                        { value: 'basic', label: 'Basic Auth' },
                        { value: 'bearer', label: 'Bearer Token' },
                        { value: 'oauth2', label: 'OAuth 2.0' },
                      ]}
                    />
                  </div>
                )
                : null
            }

            {
            form.watch('api.authentication.record_type') && form.watch('api.authentication.record_type') !== 'none'
              ? (
                <div className="mt-6 gap-y-6 gap-x-4">
                  <div className="border rounded-md p-4 md:col-span-2">

                    <span className="flex text-sm  text-gray-500">
                      <InformationCircleIcon className="text-indigo-500 h-5 w-5 mr-1 mb-4" />
                      <span className="flex">
                        <p className="font-medium mr-1 text-gray-700">{form.watch('name')}</p>
                        {' '}
                        uses
                        {' '}
                        <p className="font-medium mx-1 text-gray-700">{authDesc(form.watch('api.authentication.record_type'))}</p>
                        {' '}
                        authentication.
                      </span>
                    </span>

                    <div className="grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
                      { form.watch('api.authentication.record_type') === 'api_key' ? <ApiKeySubForm form={form} /> : null }
                      { form.watch('api.authentication.record_type') === 'basic' ? <BasicSubForm form={form} /> : null }
                      { form.watch('api.authentication.record_type') === 'bearer' ? <BearerSubForm form={form} /> : null }
                      { form.watch('api.authentication.record_type') === 'oauth2' ? <OAuthTwoSubForm form={form} connectionTemplate={connectionTemplate} /> : null }
                    </div>
                    <ScopeInputWrapper form={form} connectionTemplate={null} editMode={false} />
                  </div>
                </div>
              )
              : null
          }
          </div>
          <Footer
            submitButtonText="Update Auth"
            submitButtonDisabled={isSubmitting}
            submitButtonType="submit"
            onBackButtonClick={() => navigate(-1)}
            backButtonText="Back to Connection"
          />
        </div>
      </form>
    </>
  );
}

ConnectionForm.propTypes = {
  connection: ConnectionShape.isRequired,
};
