/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _ from 'lodash';
import ConnectionShape from '../../../shapes/ConnectionShape';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';

export default function ConnectionTemplateSubForm({
  connectionTemplate = null,
  templates = [],
  setConnectionTemplate,
  setSkipUsingTemplate,
  skipUsingTemplate = false,
}) {
  const templatesList = _.sortBy(templates, 'name');
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
      <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
        <div className="space-y-6 divide-y divide-gray-200">
          <div className="pt-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">Connection Type</h3>
              <p className="mt-1 text-sm text-gray-500">
                Let's start by selecting an existing connection type or create a custom connection.
              </p>
            </div>
            <div className="mt-4 grid grid-cols-1 gap-1 sm:grid-cols-2 md:grid-cols-3 ">
              {templatesList.map((t) => (
                <button
                  key={t.id}
                  disabled={skipUsingTemplate}
                  type="button"
                  onClick={() => setConnectionTemplate(t)}
                  className={classNames(
                    skipUsingTemplate ? 'text-gray-500 bg-gray-50' : 'text-gray-700 bg-white hover:bg-gray-50',
                    'transition-all transition-discrete mt-1 flex min-w-content rounded-md mr-2 py-2 px-2.5 text-sm font-medium border border-gray-300',
                    connectionTemplate?.id === t.id ? 'border-2 border-indigo-600 bg-indigo-100 hover:bg-indigo-100' : null,
                  )}
                >
                  <span className="my-auto mr-1">
                    {connectionAvatar(t, { size: 'xs', container: false, displayFallback: false })}
                  </span>
                  {t.name}
                </button>

              ))}
            </div>
          </div>
        </div>
        <div className="col-span-2 mt-6 mb-3">
          {
            !connectionTemplate && !skipUsingTemplate
              ? (
                <span className="text-sm text-gray-500">
                  Don't see what you're looking for?
                  <button
                    type="button"
                    className="ml-1 text-indigo-700"
                    onClick={() => setSkipUsingTemplate(true)}
                  >
                    Create a custom connection.
                  </button>
                </span>
              ) : null
          }
        </div>
      </div>
    </div>
  );
}

ConnectionTemplateSubForm.propTypes = {
  templates: PropTypes.arrayOf(ConnectionShape),
  connectionTemplate: ConnectionShape,
  setConnectionTemplate: PropTypes.func.isRequired,
  skipUsingTemplate: PropTypes.bool,
  setSkipUsingTemplate: PropTypes.func.isRequired,
};
