/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState, useEffect } from 'react';
import useClient from '../hooks/useClient';
import useAuth from './AuthContext';

const CurrentUserContext = createContext();
const { Provider } = CurrentUserContext;

export function CurrentUserProvider({ children }) {
  const { accessToken } = useAuth();
  const [adminView, setAdminView] = useState(false);

  const config = {
    retry: false,
    enabled: !!accessToken,
  };

  const { data: accounts, isLoading: isLoadingAccounts } = useClient('accounts').list();
  useEffect(() => {
    if (accounts?.length > 1) setAdminView(true);
  }, [accounts?.length]);

  const { data: currentUser, isLoading: isLoadingCurrentUser } = useClient('users').fetchMe(config);
  const { data: connections = [], isLoading: isLoadingConnections } = useClient('connections').list({ template: false, page_size: 100 }, config);
  const { data: connectionTemplates = [] } = useClient('connections').list({ template: true, page_size: 100 }, config);
  const { data: actions = [], isFetching: isLoadingActions } = useClient('actions').list({ page_size: 100, template: false }, config);
  const { data: actionTemplates = [] } = useClient('actions').list({ page_size: 100, template: true }, config);
  const { data: workflows = [], isFetching: isLoadingWorkflows } = useClient('workflows').list({ page_size: 100, template: false }, config);
  const { data: resources = [], isFetching: isLoadingResources } = useClient('resources').list({ page_size: 100, template: false }, config);
  const { data: shapes = [], isFetching: isLoadingShapes } = useClient('shapes').list({ page_size: 100, template: false }, config);
  const { data: eventData = {} } = useClient('events/about').list({ page_size: 200 }, config);

  if (
    !!accessToken
    && isLoadingCurrentUser
    && isLoadingAccounts
    && isLoadingActions
    && isLoadingConnections
    && isLoadingWorkflows
    && isLoadingResources
    && isLoadingShapes
  ) return null;

  return (
    <Provider
      value={{
        accounts,
        actions,
        actionTemplates,
        allActions: [...actions, ...actionTemplates],
        allConnections: [...connections, ...connectionTemplates],
        connections,
        connectionTemplates,
        currentUser: currentUser?.user,
        adminView,
        isLoadingCurrentUser,
        toggleAdminView: () => setAdminView(!adminView),
        admin: accounts?.length > 1,
        currentEntity: currentUser?.entity,
        workflows,
        resources,
        shapes,
        eventTopics: eventData.topics || [],
      }}
    >
      {children}
    </Provider>
  );
}

CurrentUserProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function CurrentUserConsumer() {
  return useContext(CurrentUserContext);
}
