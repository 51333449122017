/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { TrashIcon, CodeBracketIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/PageHeader';
import DeleteConfirm from '../../../components/DeleteConfirm';
// import RetryConfirm from './RetryConfirm';
import InfoCard from './InfoCard';
import BodyWrapper from '../../../components/layout/ShowPage/BodyWrapper';
import PageWrapper from '../../../components/layout/PageWrapper';
import ShapeShape from '../../../shapes/DataShapeShape';

export default function View({
  shape,
  onDestroy,
}) {
  const [modal, setModal] = useState(null);
  const navigate = useNavigate();

  const headerButtons = [
    { label: 'Edit', icon: PencilIcon, onClick: () => navigate('edit') },
    { label: 'Delete', icon: TrashIcon, onClick: () => setModal('delete') },
  ];

  return (
    <PageWrapper>
      <DeleteConfirm
        title="Delete Shape"
        description="Are you sure you want to permanently delete this shape?"
        open={modal === 'delete'}
        onClose={() => setModal(null)}
        onConfirm={onDestroy}
      />
      <Header
        description={`A detailed look at the ${shape.name}`}
        name="Data Shape Detail Page"
        buttons={headerButtons}
        icon={<CodeBracketIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <BodyWrapper>
        <InfoCard shape={shape} />
      </BodyWrapper>
      <div className="flex mt-4">
        <button type="button" onClick={() => navigate('/entity/details')} className="text-indigo-700 text-md flex">
          <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mt-1"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.1279 3.34088C12.4574 3.67038 12.4574 4.20462 12.1279 4.53412L7.66199 9L12.1279 13.4659C12.4574 13.7954 12.4574 14.3296 12.1279 14.6591C11.7984 14.9886 11.2641 14.9886 10.9346 14.6591L5.87213 9.59662C5.54262 9.26712 5.54262 8.73288 5.87213 8.40338L10.9346 3.34088C11.2641 3.01137 11.7984 3.01137 12.1279 3.34088Z"
              fill="#4338CA"
            />
          </svg>
          Back to Entity Detail Page
        </button>
      </div>
    </PageWrapper>
  );
}

View.propTypes = {
  shape: ShapeShape.isRequired,
  onDestroy: PropTypes.func.isRequired,
};
