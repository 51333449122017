import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import View from './View';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';

export default function EventShowPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const eventsClient = useClient('events');

  const { data: event, isLoading: isLoadingEvent } = eventsClient.get(id);

  const { isLoading: isDestroying, mutate: destroy } = eventsClient.destroy(
    id,
    { onSuccess: () => navigate(-1) },
  );

  const { isLoading: isRetrying, mutate: retry } = eventsClient.post(
    id,
    'retry',
    { onSuccess: () => null },
  );

  const isLoading = [
    isLoadingEvent,
    isDestroying,
    isRetrying,
  ].includes(true);

  if (isLoading) return <Loading />;

  return (
    <View
      event={event}
      onDestroy={destroy}
      onRetry={retry}
    />
  );
}
