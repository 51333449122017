import React from 'react';
import { PiPlugsConnectedThin, PiPlugsThin, PiCheckCircleThin } from 'react-icons/pi';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { PencilIcon } from '@heroicons/react/24/outline';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import ConnectionShape from '../../../../../shapes/ConnectionShape';
import { apiConfigStatusPretty, apiConfigStatus, authType } from '../../../../../helpers/ConnectionHelpers';
import OAuthTwo from './OAuthTwo';
import Bearer from './Bearer';
import ApiKey from './ApiKey';
import Basic from './Basic';
import useMe from '../../../../../context/CurrentUserContext';

export default function ApiCard({ connection, hideCredentials = false }) {
  const configStatus = apiConfigStatus(connection);
  const { api: { authentication } } = connection;
  const { adminView } = useMe();
  const navigate = useNavigate();
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              API Information
            </h2>
            {
              adminView
                ? (
                  <button
                    key="signature"
                    type="button"
                    onClick={() => navigate('edit-auth')}
                    className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
                  >
                    <PencilIcon className="h-3 w-3 mr-2 mt-0.5 text-indigo-600" aria-hidden="true" />
                    <p>Edit</p>
                  </button>
                )
                : null
            }
          </div>
          <div className="mx-auto mt-6 px-4 sm:px-6 lg:px-8 mb-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <dt className="text-sm font-medium text-gray-500">API URL</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {connection.api?.url}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Auth Type</dt>
                <dd className="mt-1 text-sm text-gray-900">{authType(connection)}</dd>
              </div>
              {
                !connection.template
                  ? (
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">Auth Status</dt>
                      <dd className="mt-1 text-sm text-gray-900 flex space-x-2">
                        {apiConfigStatusPretty(connection)}
                        {
                        configStatus === 'oauth2_installed'
                          ? (
                            <PiPlugsConnectedThin size={14} className="text-teal-700 my-0.5 mx-1" />
                          )
                          : null
                      }
                        {
                        configStatus === 'oauth2_pending'
                          ? (
                            <PiPlugsThin size={14} className="text-yellow-700 my-0.5 mx-1" />
                          )
                          : null
                      }
                        {
                        configStatus === 'has_creds' || configStatus === 'no_creds_required'
                          ? (
                            <PiCheckCircleThin size={14} className="text-green-700 mt-1 mx-1" />
                          )
                          : null
                      }
                      </dd>
                    </div>
                  )
                  : null
              }
              { !hideCredentials && authentication?.record_type === 'oauth2' ? <OAuthTwo connection={connection} /> : null}
              { !hideCredentials && authentication?.record_type === 'bearer' ? <Bearer connection={connection} /> : null}
              { !hideCredentials && authentication?.record_type === 'api_key' ? <ApiKey connection={connection} /> : null}
              { !hideCredentials && authentication?.record_type === 'basic' ? <Basic connection={connection} /> : null}
            </dl>
          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
}

ApiCard.propTypes = {
  connection: ConnectionShape.isRequired,
  hideCredentials: PropTypes.bool,
};
