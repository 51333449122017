/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { ImInsertTemplate } from 'react-icons/im';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { RxReset } from 'react-icons/rx';
import { IoCaretDownOutline, IoCaretUpOutline } from 'react-icons/io5';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import ConnectionShape from '../../../shapes/ConnectionShape';
import useClient from '../../../hooks/useClient';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import InputBase from '../../../components/forms/InputBase';
import { threadLabel } from '../../../helpers/EventHelpers';
import EventShape from '../../../shapes/EventShape';
import ComboBox from '../../../components/forms/ComboBox';
import JsonDisplay from '../../../components/JsonDisplay';
import LinkButton from '../../../components/buttons/LinkButton';
import useMe from '../../../context/CurrentUserContext';

export default function DescriptionTemplateModal({
  onClose, connection, event, events,
}) {
  const cancelButtonRef = useRef(null);
  const [displayPreview, setDisplayPreview] = useState(false);

  const { connectionTemplates } = useMe();
  const connectionTemplate = connectionTemplates?.find((ct) => ct.id === connection.parent_id);

  const navigate = useNavigate();
  const client = useClient('connections');
  const defaultTemplateKey = connection?.metadata?.threads?.[event.topic];
  const defaultTemplate = connection?.metadata?.events?.[defaultTemplateKey];
  const form = useForm({
    defaultValues: {
      eventTopic: defaultTemplateKey,
      template: defaultTemplate,
    },
  });

  const { isLoading, mutate } = client.update(
    connection.id,
    {
      onSuccess: ({ id }) => navigate(`/connections/${id}`),
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const mergedConnectionCxn = {
    metadata: {
      ...connection.metadata,
      threads: {
        ...connection.metadata?.threads,
        [event.topic]: form.watch('eventTopic'),
      },
      events: {
        ...connection.metadata?.events,
        [form.watch('eventTopic')]: form.watch('template'),
      },
    },
  };

  const onSubmit = form.handleSubmit(
    () => mutate({ connection: mergedConnectionCxn }),
  );

  return (
    <Transition.Root show as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all min-w-[420px] md:w-[720px]">
                <form onSubmit={onSubmit}>
                  <div className="sm:flex sm:items-start border-b border-gray-200 pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 flex flex-row ">
                        <ImInsertTemplate className="h-6 w-6 text-indigo-600 mr-2" aria-hidden="true" />
                        Add Description Template
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Add a description template for alls threads with this topic.
                        </p>
                      </div>
                    </div>
                  </div>
                  {
                    connectionTemplate && connectionTemplate.metadata?.threads?.[event.topic] ? (
                      <div className="rounded-md bg-blue-50 p-4 border border-blue-600">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-medium text-blue-800">Template Defaults Found</h3>
                            <div className="mt-2 text-sm text-blue-700">
                              <p>
                                The connection template has a default description template for threads with this topic.
                              </p>
                              <div className="mt-2 flex justify-end">
                                <LinkButton
                                  type="button"
                                  buttonText="Set Defaults from Template"
                                  className="font-semibold border border-blue-600 bg-blue-50 text-blue-800 px-2 py-1 rounded-md"
                                  onClick={() => {
                                    const templateTopic = connectionTemplate.metadata.threads[event.topic];
                                    const templateTemplate = connectionTemplate.metadata.events[templateTopic];
                                    form.setValue('eventTopic', templateTopic);
                                    form.setValue('template', templateTemplate);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null
                  }
                  <div className="mt-4">
                    <ComboBox
                      name="eventTopic"
                      form={form}
                      label="Event To"
                      placeholder="Enter a description template..."
                      helperText="Select an event in this thread to extract data from."
                      options={events.map((e) => ({ label: e.topic, value: e.topic }))}
                    />
                  </div>
                  <div className="mt-4">
                    <InputBase
                      name="template"
                      form={form}
                      label="Template"
                      placeholder="Enter a thread description template..."
                      helperText={(
                        <>
                          <p>
                            Initial Value: &ldquo;
                            {connection.metadata?.events?.[defaultTemplateKey]}
                            &rdquo;
                          </p>
                          <p>
                            Initial Preview: &ldquo;
                            {threadLabel(event, events, connection)}
                            &rdquo;
                          </p>
                          {defaultTemplate !== form.watch('template') ? (
                            <p>
                              Pending Preview: &ldquo;
                              {threadLabel(event, events, mergedConnectionCxn)}
                              &rdquo;
                            </p>
                          )
                            : null}
                        </>
                      )}
                    >
                      <button
                        className="justify-center rounded-md border border-transparent shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm flex"
                        type="button"
                        onClick={() => form.setValue('template', defaultTemplate)}
                      >
                        <RxReset className="h-3 w-3 mt-0.5 text-white mr-1" aria-hidden="true" />
                        Reset
                      </button>
                    </InputBase>
                  </div>
                  <div className="mt-4">
                    <div className="flex justify-end">
                      <LinkButton
                        type="button"
                        onClick={() => setDisplayPreview(!displayPreview)}
                        buttonText={displayPreview ? (
                          <div className="flex items-center">
                            <IoCaretUpOutline className="h-4 w-4 mr-1" />
                            Reduce Connection Before/After
                          </div>
                        ) : (
                          <div className="flex items-center">
                            <IoCaretDownOutline className="h-4 w-4 mr-1" />
                            Expand Connection Before/After
                          </div>
                        )}
                      />
                    </div>
                    <Transition
                      show={displayPreview}
                      enter="transition-all duration-300 ease-out"
                      enterFrom="transform scale-y-0 opacity-0 h-0"
                      enterTo="transform scale-y-100 opacity-100 h-auto"
                      leave="transition-all duration-200 ease-in"
                      leaveFrom="transform scale-y-100 opacity-100 h-auto"
                      leaveTo="transform scale-y-0 opacity-0 h-0"
                      className="overflow-hidden"
                    >
                      <div className="mt-2">
                        <label htmlFor="before" className="block text-sm font-medium text-gray-700">Before</label>
                        <JsonDisplay
                          id="before"
                          aria-labelledby="before-label"
                          valueObject={{
                            threads: connection.metadata?.threads,
                            events: connection.metadata?.events,
                          }}
                        />
                        <label htmlFor="after" className="mt-2 block text-sm font-medium text-gray-700">After</label>
                        <JsonDisplay
                          id="after"
                          valueObject={{
                            threads: mergedConnectionCxn.metadata?.threads,
                            events: mergedConnectionCxn.metadata?.events,
                          }}
                        />
                      </div>
                    </Transition>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      disabled={isLoading}
                      type="submit"
                      className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                      Submit
                    </button>
                    <button
                      type="button"
                      disabled={isLoading}
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={onClose}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

DescriptionTemplateModal.propTypes = {
  connection: ConnectionShape.isRequired,
  onClose: PropTypes.func.isRequired,
  event: EventShape.isRequired,
  events: PropTypes.arrayOf(EventShape).isRequired,
};
