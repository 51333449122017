import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import View from './View';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';

export default function ShowPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const client = useClient('resources');
  const { data, isLoading } = client.get(id);
  const queryClient = useQueryClient();

  const { isLoading: isDestroying, mutate: destroy } = client.destroy(
    id,
    {
      onSuccess: () => {
        navigate(-1);
        queryClient.invalidateQueries('resources');
      },
    },
  );

  const isLoadingSomething = [
    isLoading,
    isDestroying,
  ].includes(true);

  if (isLoadingSomething) return <Loading />;
  return (<View resource={data} onDestroy={destroy} />);
}
