/* eslint-disable max-len */
import React from 'react';
import { PlusCircleIcon, SignalIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import TableBody from './Body';
import FilterForm from './FilterForm';
import TableHeader from './Header';
import FilterBar from '../../../components/layout/FilterBar/V2FitlerBar';
import PageHeader from '../../../components/layout/PageHeader';
import ItemList from '../../../components/ItemsList';
import useIndexFetchClient from '../../../hooks/useIndexFetchClient';
import SearchBar from '../../../components/layout/SearchBar';
import useMe from '../../../context/CurrentUserContext';

const config = {
  clientName: 'actions',
  resourceName: 'Action',
  description: 'A list of all Actions that are occuring in your Source Connections',
  mobileTitle: 'Action',
  icon: <SignalIcon className="h-8 w-8 m-1 text-indigo-700" />,
  indexClientOptions: {
    searchParams: { template: false },
  },
};

export default function IndexPage() {
  const { adminView } = useMe();

  // Fetch Index Page Base Data
  const indexClient = useIndexFetchClient(config);

  // Buttons for Top Bar
  const navigate = useNavigate();
  const buttons = [
    { label: 'Filter', icon: AdjustmentsVerticalIcon, onClick: () => indexClient.setFilterOpen(true) },
  ];
  if (adminView) {
    buttons.push({ label: 'New Action', icon: PlusCircleIcon, onClick: () => navigate('new') });
  }
  return (
    <div>
      <PageHeader
        description={config.description}
        name={`${config.resourceName} List`}
        buttons={buttons}
        icon={config.icon}
      />
      <FilterBar
        resource={`${config.resourceName}s`}
        indexClient={indexClient}
      >
        <FilterForm indexClient={indexClient} />
      </FilterBar>
      <SearchBar
        name="search"
        label="Search Actions"
        indexClient={indexClient}
        placeholder="Search for Actions by Name..."
        statusTabConfig={{
          label: 'Filter by Type',
          filterKey: 'template',
          tabs: [
            { name: 'Actions', value: 'false' },
            { name: 'Templates', value: 'true' },
          ],
        }}
      />
      <ItemList
        body={<TableBody items={indexClient.data} />}
        indexClient={indexClient}
        header={<TableHeader />}
      />
    </div>
  );
}
