/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import FormShape from '../../../../shapes/FormShape';
import JSONInput from '../../../../components/forms/JSONInput';

export default function FieldsSubForm({ form }) {
  return (
    <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
      <div className="w-full bg-white px-10 border border-gray-300 rounded">
        <div className="pt-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Fields</h3>
          <p className="mt-1 text-sm text-gray-500">
            Specify the data type expected for each key/value pair.
          </p>
        </div>
        <div className="mt-2 mb-8 w-full">
          <JSONInput form={form} name="fields" />
        </div>
      </div>
    </div>
  );
}

FieldsSubForm.propTypes = {
  form: FormShape.isRequired,
};
