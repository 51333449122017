/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassCircleIcon } from '@heroicons/react/24/outline';
import { get } from 'lodash';
import ActionShape from '../../../../shapes/ActionShape';
import useClient from '../../../../hooks/useClient';
import Modal from '../../../../components/Modal';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import Loading from '../../../../components/Loading';
import { formatTime } from '../../../../helpers/DateHelpers';
import LinkButton from '../../../../components/buttons/LinkButton';
import LogDetailModalBody from '../LogDetailModalBody';
import RequestResponseStatusBadge from '../../../../components/Badges/RequestResponseStatusBadge';
import LogShape from '../../../../shapes/LogShape';

export default function ApiHistory({ action }) {
  const [search, setSearch] = useState('');
  const [newSearch, setNewSearch] = useState('');
  const [currentLog, setCurrentLog] = useState(null);
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);

  const [date, setDate] = useState(DateTime.now());
  const isoDate = date.toISODate();

  const startDate = date.startOf('day').toISO();
  const endDate = date.endOf('day').toISO();

  const { data, meta, isLoading } = useClient('logs').list(
    {
      page,
      records: { action_id: action.id },
      page_size: 100,
      topic: 'action.processed',
      start_date: startDate,
      end_date: endDate,
    },
    { enabled: !action.template },
  );

  const safeParse = (a) => {
    if (!a) return '{}';
    try {
      return JSON.parse(a);
    } catch {
      // do nothing
    }
  };

  useEffect(() => {
    const parsedData = data ? data.map((log) => (
      {
        ...log,
        body: {
          ...log.body,
          request_obj: JSON.parse(log.body.request || '{}'),
          response_obj: safeParse(log.body.response),
        },
      }
    )) : [];
    const uniqueLogs = [...logs, ...parsedData].filter((log, index, self) => index === self.findIndex((t) => t.id === log.id));
    setLogs(uniqueLogs);
    if (meta?.page && meta.page < meta.total_pages) {
      setPage(page + 1);
    }
  }, [meta?.page]);

  useEffect(() => {
    setLogs([]);
    setPage(1);
  }, [date]);

  if (action.template) return null;

  function getAllNestedValues(obj) {
    if (!obj || typeof obj !== 'object') {
      return [];
    }

    return Object.values(obj).reduce((acc, value) => {
      if (value && typeof value === 'object') {
        return [...acc, ...getAllNestedValues(value)];
      }
      return [...acc, value];
    }, []);
  }

  const filteredLogs = logs.filter((log) => {
    if (!search || search === '') return true;

    const list = get(log.body.response_obj, action.after_action?.response_key?.join('.'));

    // Check if list exists and is an array
    if (!list || !Array.isArray(list)) return false;

    // Search through all objects in results array
    return list.some((result) => {
      // Get all values from the result object
      const values = getAllNestedValues(result);

      // Check if any value matches the search term
      return values.some((value) => String(value).toLowerCase().includes(search.toLowerCase()));
    });
  });

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              API
              {' '}
              {action.pagination ? 'Polling' : 'Request'}
              {' '}
              History
            </h2>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              {logs?.length}
              {' '}
              requests found on
              {' '}
              {date.toLocaleString(DateTime.DATE_MED)}
              .
              {
                isLoading ? ' Fetching additional requests...' : ''
              }
            </p>

            <div className="flex">
              <button
                type="button"
                aria-label="Previous Day"
                onClick={() => {
                  const prevDay = date.minus({ days: 1 });
                  setDate(prevDay);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
              </button>
              <input
                className="h-7 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block text-xs border-gray-300 rounded-sm mx-2"
                type="date"
                value={isoDate}
                onChange={(e) => {
                  e.preventDefault();
                  setDate(DateTime.fromISO(e.target.value));
                }}
              />
              <button
                type="button"
                aria-label="Next Day"
                onClick={() => {
                  const nextDay = date.plus({ days: 1 });
                  setDate(nextDay);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
              </button>
            </div>
          </div>
          <Modal maxWidth="sm:max-w-5xl sm:w-full" open={!!currentLog} onClose={() => setCurrentLog(null)}>
            <LogDetailModalBody log={currentLog} onClose={() => setCurrentLog(null)} />
          </Modal>
          {
            isLoading && page === 1
              ? (<Loading className="flex items-center justify-center w-full my-28 h-12" />)
              : (
                logs?.length
                  ? (
                    <>
                      <div className="flex flex-col md:flex-row w-full items-start md:items-center mb-4">
                        <div className="w-full md:w-3/4 lg:w-3/5 xl:w-1/2 h-16">
                          <div className="inline-block align-baseline w-full m-4">
                            <label htmlFor="Search" className="block text-sm font-medium text-gray-700">
                              Search Responses
                            </label>
                            <div className="mt-1 flex">
                              <input
                                type="text"
                                // disabled={disabled}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
                                onChange={({ target: { value: v } }) => setNewSearch(v)}
                                // onBlur={onBlur}
                                // ref={ref}
                                value={newSearch}
                                placeholder="Search Responses..."
                              />
                              <button
                                type="button"
                                onClick={() => setSearch(newSearch)}
                                className="ml-2 inline-flex items-center rounded border border-transparent bg-indigo-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                              >
                                Search
                                <MagnifyingGlassCircleIcon className="ml-2 -mr-0.5 h-5 w-5" aria-hidden="true" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Table action={action} logs={filteredLogs} setCurrentLog={setCurrentLog} />
                    </>
                  )
                  : (
                    <p className="m-4 max-w-2xl text-sm text-gray-500">
                      There are currently no API Request for this action on this date!
                    </p>
                  )
              )
          }
        </div>
      </ErrorBoundary>
    </section>
  );
}

ApiHistory.propTypes = {
  action: ActionShape.isRequired,
};

export function Table({ action, logs, setCurrentLog }) {
  return (
    <>
      {/* Projects list (only on smallest breakpoint) */}
      <div className="sm:hidden">
        <div className="px-4 sm:px-6">
          <h2 className="text-sm font-medium text-gray-900 mt-2">Requests</h2>
        </div>
        <ul className="mt-3 divide-y divide-gray-100 border-t border-gray-200">
          {logs.map((log) => (
            <li key={log.id}>
              <button
                type="button"
                aria-label="Display Log"
                className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentLog(log);
                }}
              >
                <span className="flex items-center space-x-3 truncate">
                  <span className="truncate text-xs font-medium leading-6">
                    <span className="font-heavy text-indigo-700 mr-1">
                      {log.body.method}
                    </span>
                    <span className="truncate font-normal text-gray-500">
                      {log.body.url}
                    </span>
                  </span>
                </span>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Projects table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="inline-block min-w-full border-b border-gray-200 align-middle p-4">
          <table className="min-w-full  border border-gray-200">
            <thead>
              <tr className="border-t border-gray-200">
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Method + URL
                </th>
                {
                  action.after_action?.type === 'multiple'
                    ? (
                      <th
                        scope="col"
                        className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                      >
                        Records
                      </th>
                    ) : null
                }
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                >
                  <span id="timestamp-header">Timestamp</span>
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900"
                  aria-label="Actions"
                />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {logs.map((log) => {
                const url = log.body.url ? new URL(log.body.url) : '';

                // Get the pathname and search parameters
                const { pathname, search } = url;

                // Combine them to create the URL without the base
                const urlWithoutBase = pathname + search;

                return (
                  <tr key={log.id}>
                    <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-900">
                      <div className="flex flex-wrap items-center space-x-3 lg:pl-2">
                        <span className="truncate">
                          <span>
                            <span className="font-heavy text-indigo-700 mr-2">
                              {log.body.method}
                            </span>
                            <span className="font-normal text-gray-500">
                              {urlWithoutBase}
                            </span>
                          </span>
                        </span>
                      </div>
                    </td>
                    {
                      action.after_action?.type === 'multiple'
                        ? (
                          <td className="px-6 py-3 text-xs font-medium text-gray-500">
                            {
                          log.status === 'success'
                            ? (
                              action.after_action?.response_key
                                ? get(log.body.response_obj, action.after_action?.response_key?.join('.')).length
                                : log.body.response_obj.length
                            )
                            : null
                        }
                          </td>
                        )
                        : null
                    }
                    <td className="px-6 py-3 text-xs font-medium text-gray-500">
                      <RequestResponseStatusBadge httpStatus={log.body.http_status} />
                    </td>
                    <td className="hidden whitespace-nowrap px-6 py-3 text-right text-xs text-gray-500 md:table-cell">
                      {formatTime(log.inserted_at)}
                    </td>
                    <td className="whitespace-nowrap px-6 py-3 text-right text-sm font-medium">
                      <LinkButton className="text-xs" buttonText="Details" onClick={() => setCurrentLog(log)} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>

  );
}

Table.propTypes = {
  setCurrentLog: PropTypes.func.isRequired,
  logs: PropTypes.arrayOf(LogShape).isRequired,
  action: ActionShape.isRequired,
};
