import PropTypes from 'prop-types';

export default PropTypes.shape({
  record_type: PropTypes.oneOf(['resource']),
  // eslint-disable-next-line react/forbid-prop-types
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  inserted_at: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metadata: PropTypes.object,
  name: PropTypes.string.isRequired,
  icon_url: PropTypes.string.isRequired,
});
