/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import React, { useEffect } from 'react';
import Input from '../../../../../components/forms/InputBase';
import FormShape from '../../../../../shapes/FormShape';
import ConnectionShape from '../../../../../shapes/ConnectionShape';

export default function OAuthTwoSubForm({ form, connectionTemplate = null }) {
  useEffect(() => {
    if (!form.watch('api.authentication.client_id') && !form.watch('api.authentication.client_secret')) {
      form.setValue(
        'api.authentication',
        {
          record_type: 'oauth2',
          access_token_url: '',
          auth_url: '',
          client_id: '',
          client_secret: '',
          scope: '',
          auth_parameter: '',
        },
      );
    }
  }, []);

  const templateClientId = connectionTemplate?.api.authentication.client_id;
  const templateClientSecret = connectionTemplate?.api.authentication.client_secret;

  return (
    <div className="mt-2">
      <div className="mb-4">
        <Input
          form={form}
          label="Client ID"
          type={templateClientId ? 'password' : 'text'}
          disabled={!!templateClientId}
          name="api.authentication.client_id"
          placeholder="Client ID..."
        />
      </div>
      <div className="mb-4">
        <Input
          form={form}
          label="Client Secret"
          type={templateClientSecret ? 'password' : 'text'}
          disabled={!!templateClientSecret}
          name="api.authentication.client_secret"
          placeholder="Client Secret..."
        />
      </div>
      <div className="mb-4">
        <Input
          form={form}
          label="Scope"
          name="api.authentication.scope"
          placeholder="Scope..."
        />
      </div>

    </div>
  );
}

OAuthTwoSubForm.propTypes = {
  form: FormShape.isRequired,
  connectionTemplate: ConnectionShape,
};
