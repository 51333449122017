/* eslint-disable max-len */
import React from 'react';
import { PlusIcon } from '@heroicons/react/24/outline';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import useMe from '../../../../context/CurrentUserContext';
import EmptyState from '../../../Workflow/ShowPage/View/ActionList/EmptyState';
import ShowPageSectionCard from '../../../../components/layout/ShowPage/SectionCard';
import LinkButton from '../../../../components/buttons/LinkButton';
import Workflow from '../../../Workflow/IndexPage/Workflow';

export default function WorkflowCards({ connection, connectionTemplate }) {
  const { actions, workflows } = useMe();
  const destinationActions = actions.filter((a) => a.destination_connection_id === connection.id);
  const webhookTriggeredActions = actions.filter((a) => a.source_connection_id === connection.id);
  const apiTriggeredActions = destinationActions?.filter((a) => !!a.schedule);
  const apiTerminatingActions = destinationActions?.filter((a) => !!a.topic);

  const workflowIds = [...webhookTriggeredActions, ...apiTerminatingActions, ...apiTriggeredActions].map((a) => a.workflow_id);
  const workflowss = workflows.filter((w) => workflowIds.includes(w.id));

  if (!workflowss.length) {
    return (
      <ShowPageSectionCard
        title="Workflows"
      >
        <div className="text-center">
          <h3 className="mt-2 text-sm font-semibold text-gray-900">
            No workflows
          </h3>
          <p className="mt-1 text-sm text-gray-500">Get started by creating a new Workflow.</p>
          <div className="mt-6">
            <LinkButton
              path="/workflows/new"
              className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              buttonText={(
                <span className="flex">
                  <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                  <p>New Workflow</p>
                </span>
            )}
            />
          </div>
        </div>
      </ShowPageSectionCard>
    );
  }
  return (
    <div>
      {workflowss.map((w) => (
        <ShowPageSectionCard
          key={w.id}
          title={w.name}
        >
          {
            actions.length
              ? <Workflow workflowActions={actions.filter((a) => a.workflow_id === w.id)} />
              : <EmptyState workflow={w} />
          }
        </ShowPageSectionCard>
      ))}
    </div>
  );
}

WorkflowCards.propTypes = {
  connection: ConnectionShape.isRequired,
};
