/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import { displayInputError } from '../../helpers/FormHelpers';
import FormShape from '../../shapes/FormShape';

export default function Input({
  form,
  label,
  name,
  required = false,
  type = 'text',
  helperText = null,
  placeholder = null,
}) {
  const {
    control,
    formState: { errors },
  } = form;

  const {
    field: {
      onChange, onBlur, ref, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  return (
    <div className="grid grid-cols-3 gap-6">
      <div className="col-span-3">
        <label htmlFor={name} className="block text-sm font-medium text-gray-700">
          {label}
        </label>
        <div className="mt-1">
          <input
            type={type}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
            value={value || ''}
            placeholder={placeholder}
          />
        </div>
        { typeof helperText === 'string' ? <p className="mt-2 text-sm text-gray-500">{helperText}</p> : helperText }
        <p>{displayInputError(errors, name)}</p>
      </div>
    </div>
  );
}

Input.propTypes = {
  form: FormShape.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password']),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
};
