import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import Loading from '../../../components/Loading';
import useClient from '../../../hooks/useClient';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import Form from '../Form';
import DataShapeShape from '../../../shapes/DataShapeShape';

export default function EditPageWrapper() {
  const { id } = useParams();
  const client = useClient('shapes');
  const { data: shape, isLoading } = client.get(id);
  if (isLoading) return <Loading />;
  return (<EditPage shape={shape} />);
}

function EditPage({ shape }) {
  const navigate = useNavigate();
  const form = useForm({ defaultValues: shape });
  const queryClient = useQueryClient();
  const { isLoading: isSubmitting, mutate } = useClient('shapes').update(shape.id, {
    onSuccess: () => {
      queryClient.invalidateQueries('shapes');
      navigate(`/data-shapes/${shape.id}`);
    },
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit((record) => mutate({ shape: record }));

  return (
    <Form
      onSubmit={onSubmit}
      isSubmitting={isSubmitting}
      form={form}
      submitButtonText="Update Data Shape"
      formTitle="Update Data Shape Form"
      formSubTitle="Update an existing shape's name or layout."
      displayLayoutInput
    />
  );
}

EditPage.propTypes = {
  shape: DataShapeShape.isRequired,
};
