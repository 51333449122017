/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import { formatHeaders } from '../../../../helpers/ConnectionHelpers';
import useClient from '../../../../hooks/useClient';
import mapErrorsToForm from '../../../../helpers/FormHelpers';
import BaseForm from '.';
import Footer from '../../../../components/forms/Footer';

export default function BaseFormCreate({
  connectionTemplate = null,
  skipUsingTemplate = false,
  onReset,
}) {
  const defaultValues = connectionTemplate ? {
    name: connectionTemplate?.name,
    icon_url: connectionTemplate?.icon_url,
    parent_id: connectionTemplate?.id,
    template: false,
    webhook: connectionTemplate.webhook,
  } : {};
  const form = useForm({ defaultValues });
  const navigate = useNavigate();
  const client = useClient('connections');
  const { isLoading: isSubmitting, mutate } = client.create({
    onSuccess: ({ id }) => {
      navigate(`/connections/new/${id}/configure-auth`);
    },
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit(
    (cxn) => {
      const formattedData = formatHeaders(cxn);
      return mutate({ connection: formattedData });
    },
  );

  return (
    <BaseForm
      onSubmit={onSubmit}
      form={form}
      isSubmitting={isSubmitting}
      onReset={onReset}
      skipUsingTemplate={skipUsingTemplate}
      footer={(
        <Footer
          backButtonText="Back to Connection Type"
          onBackButtonClick={onReset}
          submitButtonText="Create"
          submitButtonDisabled={isSubmitting}
          submitButtonType="submit"
        />
      )}
    />
  );
}

BaseFormCreate.propTypes = {
  connectionTemplate: ConnectionShape,
  skipUsingTemplate: PropTypes.bool,
  onReset: PropTypes.func,
};
