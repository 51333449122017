/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import { PencilIcon } from '@heroicons/react/24/outline';
import { PlusIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/layout/PageHeader';
import { connectionAvatar } from '../../../helpers/ConnectionHelpers';
import { formatDateTime } from '../../../helpers/DateHelpers';
import useMe from '../../../context/CurrentUserContext';
import ResourcesTable from './ResourcesTable';
import Modal from '../../../components/Modal';
import Form from '../EditPage/Form';
import ShapesTable from './ShapesTable';

export default function View() {
  const [modal, setModal] = useState(null);
  const { currentEntity, resources, shapes } = useMe();
  const navigate = useNavigate();

  return (
    <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8">
      <Header
        description={`Added on ${formatDateTime(currentEntity.inserted_at)}.`}
        name={currentEntity.name}
        avatar={connectionAvatar(currentEntity, { size: 'lg' })}
      />
      {
        modal === 'edit'
          ? (
            <Modal
              maxWidth="sm:max-w-5xl sm:w-full"
              open
              onClose={() => setModal(null)}
            >
              <Form onBackButtonClick={() => setModal(null)} />
            </Modal>
          )
          : null
      }
      <div className="mt-8 max-w-3xl lg:max-w-7xl">
        <div className="space-y-6 lg:col-span-2 lg:col-start-1 ">
          <section aria-labelledby="action-information-title">
            <div className="bg-white border border-gray-300 rounded">
              <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
                <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                  General Information
                </h2>
                <button
                  key="signature"
                  type="button"
                  onClick={() => setModal('edit')}
                  className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
                >
                  <PencilIcon className="h-3 w-3 mr-2 mt-0.5 text-indigo-600" aria-hidden="true" />
                  <p>Edit</p>
                </button>
              </div>
              <dl className="divide-y divide-gray-100 text-sm/6 mx-4">
                <div className="py-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Name</dt>
                  <dd className="mt-1 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{currentEntity.name}</div>
                  </dd>
                </div>
                <div className="py-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Icon URL</dt>
                  <dd className="mt-1 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900">{currentEntity.icon_url}</div>
                  </dd>
                </div>
              </dl>
            </div>
          </section>
          <section aria-labelledby="action-information-title">
            <div className="bg-white border border-gray-300 rounded">
              <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
                <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                  Resources
                </h2>
                <button
                  key="signature"
                  type="button"
                  onClick={() => navigate('/resources/new')}
                  className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
                >
                  <PlusIcon className="h-3 w-3 mr-2 mt-0.5 text-indigo-600" aria-hidden="true" />
                  <p>New Resource</p>
                </button>
              </div>
              <dl className="divide-y divide-gray-100 text-sm/6 mx-4">
                {
                  resources?.length
                    ? <ResourcesTable resources={resources} />
                    : (
                      <p className="m-4 max-w-2xl text-sm text-gray-500">
                        There are currently no Resources for this Entity.
                      </p>
                    )
                }
              </dl>
            </div>
          </section>
          <section aria-labelledby="action-information-title">
            <div className="bg-white border border-gray-300 rounded">
              <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
                <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
                  Shape
                </h2>
                <button
                  key="signature"
                  type="button"
                  onClick={() => navigate('/data-shapes/new')}
                  className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
                >
                  <PlusIcon className="h-3 w-3 mr-2 mt-0.5 text-indigo-600" aria-hidden="true" />
                  <p>New Data Shape</p>
                </button>
              </div>
              <dl className="divide-y divide-gray-100 text-sm/6 mx-4">
                {
                  shapes?.length
                    ? <ShapesTable shapes={shapes} />
                    : (
                      <p className="m-4 max-w-2xl text-sm text-gray-500">
                        There are currently no Shapes for this Entity.
                      </p>
                    )
                }
              </dl>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
