import React, { useEffect, useState } from 'react';
import Input from '../../../../../components/forms/InputBase';
import FormShape from '../../../../../shapes/FormShape';
import LinkButton from '../../../../../components/buttons/LinkButton';

export default function BearerSubForm({ form }) {
  const [hasBeenReset, setHasBeenReset] = useState(null);
  const [initialValue, setInitialValue] = useState(form.watch(''));

  useEffect(() => {
    setInitialValue(form.watch('api.authentication.token'));
    if (!form.watch('api.authentication.token')) {
      form.setValue(
        'api.authentication',
        {
          record_type: 'bearer',
          token: '',
        },
      );
    }
  }, []);

  return (
    <div className="mt-2">
      <div className="mb-4">
        <Input
          type={!!initialValue && !hasBeenReset ? 'password' : 'text'}
          disabled={!!initialValue && !hasBeenReset}
          form={form}
          label="Token"
          name="api.authentication.token"
          placeholder="Token..."
          helperText={initialValue ? (
            <LinkButton
              buttonText="Reset"
              onClick={() => {
                form.setValue('api.authentication.token', '');
                setHasBeenReset(true);
              }}
            />
          ) : null}
        />
      </div>
    </div>
  );
}

BearerSubForm.propTypes = {
  form: FormShape.isRequired,
};
