import React from 'react';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import ConnectionShape from '../../../../../shapes/ConnectionShape';
import { formatDateTime } from '../../../../../helpers/DateHelpers';
import { lastFour } from '../../../../../helpers/ConnectionHelpers';

export default function OAuthTwo({ connection }) {
  const { api: { authentication } } = connection;
  return (
    <>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Client ID</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {lastFour(authentication?.client_id)}
          <p className="text-xs text-gray-400">
            {lastFour(authentication?.client_id).length}
            {' '}
            characters
          </p>
        </dd>
      </div>
      <div className="sm:col-span-1">
        <dt className="text-sm font-medium text-gray-500">Client Secret</dt>
        <dd className="mt-1 text-sm text-gray-900">
          {lastFour(authentication?.client_secret)}
          <p className="text-xs text-gray-400">
            {lastFour(authentication?.client_secret).length}
            {' '}
            characters
          </p>
        </dd>
      </div>
      {
        // eslint-disable-next-line no-nested-ternary
        !connection.template
          ? (
            authentication?.access_token
              ? (
                <>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Access Token</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {lastFour(authentication?.access_token)}
                      <p className="text-xs text-gray-400">
                        {lastFour(authentication?.access_token)?.length}
                        {' '}
                        characters
                        {' '}
                        &middot;
                        {' '}
                        expires at
                        {' '}
                        {formatDateTime(authentication?.access_token_expires_at)}
                      </p>
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">Refresh Token</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {
                        authentication?.refresh_token
                          ? (
                            <>
                              <p className="truncate">
                                {lastFour(authentication?.refresh_token)}
                              </p>
                              <p className="text-xs text-gray-400">
                                {lastFour(authentication?.refresh_token)?.length}
                                {' '}
                                characters
                                {' '}
                                &middot;
                                {' '}
                                expires at
                                {' '}
                                {formatDateTime(authentication?.refresh_token_expires_at)}
                              </p>
                            </>
                          )
                          : <p className="truncate">N.A</p>
                      }
                    </dd>
                  </div>
                </>
              )
              : (
                <div className="sm:col-span-2">
                  <dt className="text-sm font-medium text-gray-500">Application Install</dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    <div className="rounded-md bg-yellow-50 border border-yellow-300 p-4">
                      <div className="flex space-x-2">
                        <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                        <h3 className="text-sm font-medium text-yellow-800">Pending Install</h3>
                      </div>
                      <div className="flex text-sm text-yellow-700 space-x-1">
                        <p>This app is pending installation.</p>
                        <p>Click</p>
                        <Link
                          className="text-indigo-700"
                          to={authentication?.destination_redirect_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </Link>
                        <p>to open a new tab and install.</p>
                      </div>
                    </div>
                  </dd>
                </div>
              )

          )
          : null
      }
      <div className="sm:col-span-2">
        <dt className="text-sm font-medium text-gray-500">Scope</dt>
        <dd className="mt-1 text-sm text-gray-900 flex flex-wrap">
          {
            connection.api.authentication.scope
              ? connection.api.authentication.scope.split(' ').map((scope) => (
                <div key={scope} className="w-fit mr-2 mt-2 text-indigo-700 bg-indigo-50 border border-indigo-700 rounded-md px-2 py-1 text-xs font-medium">
                  {scope}
                </div>
              ))
              : 'No scopes have been set!'
          }
        </dd>
      </div>
    </>

  );
}

OAuthTwo.propTypes = {
  connection: ConnectionShape.isRequired,
};
